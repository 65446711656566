import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as constants from '../shared/constants/defines';
import { evict } from '../shared/decorators/evict.decorator';
import { PurchasesSubscriptionsDuration } from '../shared/enums/purchases-subscriptios-duration.enum';
import { PremiumContent } from '../shared/models/premium-content.model';
import { CancelSubscription } from '../shared/models/product-cancel-subscription.model';
import { PurchasesAndSubscriptions } from '../shared/models/purchases-subscriptions.model';
import { ServiceModel } from '../shared/models/service.model';
import { SimDataModel } from '../shared/models/sim-data.model';
import { ServicesContainerModel, ThirdPartyLandingModel } from '../shared/models/third-party-landing.model';
import { UtilitiesService } from './../shared/utils/utilities.service';
import { ServiceSettingsData } from './service-settings.data';

@Injectable()
export class ServiceSettingsService {
	purchasesSubscriptions: Array<PurchasesAndSubscriptions>;
	node: ServicesContainerModel;
	nodesContainer: ServicesContainerModel[];
	ThirdPartyLandingArray: ThirdPartyLandingModel[];
	thirdPartyItem: ThirdPartyLandingModel;
	selectedService: ServiceModel;
	durationObj;
	subscriptionsPartialError: boolean;
	purchasesFullError: boolean;
	subscriptionsFullError: boolean;
	isRestrictionChanged: boolean;
	isFromProductAndService: boolean;
	constructor(
		public settingData: ServiceSettingsData,
		private utilitiesService: UtilitiesService,
		private translateService: TranslateService
	) {}

	getSIMProfileData(siteId: string, serviceId: string, serviceType?: string): Observable<any> {
		return this.settingData.getSIMProfileData(siteId, serviceId, serviceType).pipe(
			// jsonpath refactor: added 'any' type due to missing model
			map((res: any) => {
				const simProfileData: SimDataModel = new SimDataModel();
				simProfileData.sim = res.sim || null;
				simProfileData.pins = res.pins || {};
				simProfileData.puks = res.puks || {};
				simProfileData.expiryDate = res.expiryDate || null;
				return simProfileData;
			})
		);
	}
	getThirdPartyData(serviceId: string) {
		this.ThirdPartyLandingArray = new Array<ThirdPartyLandingModel>();
		return this.settingData.getThirdPartyData(serviceId).pipe(
			map((res: any) => {
				if (res.items) {
					res.items.forEach((el) => {
						const ThirdPartyLandingData: ThirdPartyLandingModel = new ThirdPartyLandingModel();
						ThirdPartyLandingData.parentId = el.parentId || null;
						ThirdPartyLandingData.isChargeToBill = el.isChargeToBill || false;
						ThirdPartyLandingData.name = el.name || null;
						this.translateService.get('payment.serviceSettings.restrictionCardsList').subscribe((wcs) => {
							ThirdPartyLandingData.title = wcs[ThirdPartyLandingData.name].title;
							ThirdPartyLandingData.shortDescription = wcs[ThirdPartyLandingData.name].shortDesc;
							ThirdPartyLandingData.longDescription = wcs[ThirdPartyLandingData.name].longDesc;
							ThirdPartyLandingData.img = wcs[ThirdPartyLandingData.name].img;
							ThirdPartyLandingData.img2 = wcs[ThirdPartyLandingData.name].img2;
						});
						this.ThirdPartyLandingArray.push(ThirdPartyLandingData);
					});
				}
				return this.ThirdPartyLandingArray;
			})
		);
	}

	postUnlockSIM(siteId: string, msisdn: string, imei: string, email: string) {
		return this.settingData.postUnlockSIM(siteId, msisdn, imei, email).pipe(map((res) => res));
	}

	deleteThirdParty(serviceId: string, subscriptionId: string) {
		return this.settingData.deleteThirdParty(serviceId, subscriptionId).pipe(map((res) => res));
	}
	getPurchasesAndSubscriptions(serviceId: string) {
		this.subscriptionsFullError = false;
		this.purchasesFullError = false;
		this.subscriptionsPartialError = false;

		this.purchasesSubscriptions = new Array<PurchasesAndSubscriptions>();
		return this.settingData.getPurchasesAndSubscriptions(serviceId).pipe(
			map((res: any) => {
				if (res.status) {
					if (res.status.includes('-1')) {
						this.subscriptionsFullError = true;
					}
					if (res.status.includes('-2')) {
						this.subscriptionsPartialError = true;
					}
					if (res.status.includes('-3')) {
						this.purchasesFullError = true;
					}
				}

				const items = res.items || [];
				this.translateService.get('payment.serviceSettings.itemsList').subscribe((data) => {
					this.durationObj = data;
				});
				items.map((item) => {
					this.purchasesSubscriptions.push(this.mapPurchasesAndSubscriptions(item));
				});
			})
		);
	}
	mapPurchasesAndSubscriptions(item) {
		const purchasesAndSubscriptionsData: PurchasesAndSubscriptions = new PurchasesAndSubscriptions();
		purchasesAndSubscriptionsData.type = item.type || null;
		purchasesAndSubscriptionsData.name = item.name || null;
		purchasesAndSubscriptionsData.price = item.price || '0';
		purchasesAndSubscriptionsData.priceUnit = item.priceUnit || null;
		purchasesAndSubscriptionsData.companyName = item.companyName || null;
		purchasesAndSubscriptionsData.companyTelephone = item.companyTelephone || null;
		purchasesAndSubscriptionsData.creationDate = this.utilitiesService.getDateFormatted(item.creationDate) || null;
		purchasesAndSubscriptionsData.subscriptionDetails = item.subscriptionDetails || null;
		if (purchasesAndSubscriptionsData.subscriptionDetails) {
			purchasesAndSubscriptionsData.subscriptionDetails.ableToUnsubscribe =
				purchasesAndSubscriptionsData.subscriptionDetails.ableToUnsubscribe || null;
			purchasesAndSubscriptionsData.subscriptionDetails.duration =
				this.getDurationText(purchasesAndSubscriptionsData.subscriptionDetails.duration) || null;
			purchasesAndSubscriptionsData.subscriptionDetails.endDate =
				purchasesAndSubscriptionsData.subscriptionDetails.endDate || null;
			purchasesAndSubscriptionsData.subscriptionDetails.subscriptionId =
				purchasesAndSubscriptionsData.subscriptionDetails.subscriptionId || null;
		}
		return purchasesAndSubscriptionsData;
	}

	@evict(constants.cachingKeys.Products)
	updatePaymentRestrictions(serviceId: string, code, changeToBill: boolean) {
		return this.settingData.updatePaymentRestrictions(serviceId, code, changeToBill).pipe(map((res) => res));
	}

	cancelSubscriptionService(
		serviceId: string,
		code: string,
		originBackend: string,
		productOfferingId: string,
		name: string
	): Observable<CancelSubscription> {
		return this.settingData
			.cancelSubscriptionService(serviceId, code, originBackend, productOfferingId, name)
			.pipe(map((res) => res));
	}
	getDurationText(key) {
		if (key) {
			switch (key.toLowerCase()) {
				case PurchasesSubscriptionsDuration.Weekly.toLowerCase():
					return this.durationObj.weekly.body;
				case PurchasesSubscriptionsDuration.Monthly.toLowerCase():
					return this.durationObj.monthly.body;
				case PurchasesSubscriptionsDuration.Yearly.toLowerCase():
					return this.durationObj.yearly.body;
				default:
					return '';
			}
		}
	}

	mapingServices = (data) => {
		this.translateService.get('payment.serviceSettings.restrictionCardsList').subscribe((wcs) => {
			this.nodesContainer = [];
			const services = _.groupBy(<ThirdPartyLandingModel[]>data, (item) => (item['parentId'] ? item['parentId'] : ''));
			const keys = Object.keys(services);
			keys
				.filter((key) => key)
				.forEach((key) => {
					const parent = new ServicesContainerModel();
					// title and desc for parent
					parent.title = wcs[key].title;
					parent.shortDescription = wcs[key].shortDesc;
					parent.longDescription = wcs[key].longDesc;
					parent.childs = services[key];
					parent.childs.map((child) => {
						// title and desc for child with Parent
						child.title = wcs[child.name].title;
						child.shortDescription = wcs[child.name].shortDesc;
						child.longDescription = wcs[child.name].longDesc;
						child.img = wcs[child.name].img;
						child.img2 = wcs[child.name].img2;
					});
					parent.img = wcs[key].img;
					parent.img2 = wcs[key].img2;
					this.nodesContainer.push(parent);
				});
		});
	};

	getPremiumContentSubscription(serviceId: string): Observable<PremiumContent> {
		return this.settingData.getPremiumContentSubscription(serviceId).pipe(map((res) => res));
	}
}
