import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as routes from '../../../../config/pages-config';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { StorageService } from '../../../core/services/storage.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { BubbleStatus } from '../../../shared/constants/defines';
import { LOCAL_STORAGE_KEYS, isUnlimited } from '../../constants/defines';
import { AttrModel } from '../../models/attr.model';
import { BundleType } from './../../enums/bundleType.enum';
import { ServiceType } from './../../enums/serviceType.enum';
import { RecommendationService } from './../../services/recommendation.service';
import { FullWidthSliderComponent } from './../full-width-slider/full-width-slider.component';

@Component({
	selector: 'sp-onboarding',
	templateUrl: './onboarding.component.html',
	styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit, OnChanges {
	private Slides = {
		intro: 'intro',
		upgrade: 'mejora',
		know: 'conoce destiny',
	};

	@ViewChild('onBoarding', { static: true }) onBoarding: FullWidthSliderComponent;

	@Input() showModal: boolean;
	private comparationSubscription: Subscription;

	private hasUnlimited: boolean;
	private hasLimited: boolean;
	private hasTV: boolean;

	public slidesSet = [];
	public titles = [];
	public description = [];
	public img = [];
	public buttonText = [];

	// Tagging constants
	private result_fee: number;

	constructor(
		private tagging: TaggingHelperService,
		private translate: TranslateService,
		private router: Router,
		private recommendationService: RecommendationService,
		private subscriptionService: SubscriptionService,
		private storage: StorageService,
		private appservice: AppService
	) {}

	ngOnChanges() {
		if (this.showModal) {
			const omit = this.storage.getLocalStorage(
				LOCAL_STORAGE_KEYS.OMIT_DESTINY_ONBOARDING + '_' + this.subscriptionService.customerData.customerAccountsId
			);

			if (!this.hasTV && !this.hasLimited && !this.hasUnlimited && !omit) {
				this.storage.setLocalStorage(
					LOCAL_STORAGE_KEYS.OMIT_DESTINY_ONBOARDING +
						'_' +
						this.subscriptionService.customerData.currentService.siteId,
					true
				);
				this.router.navigate([routes.config.Recommender.route]);
			} else {
				this.onBoarding.showSwiperModal();
				this.onBoarding.swiperNoPadding();
				this.storage.setLocalStorage(
					LOCAL_STORAGE_KEYS.OMIT_DESTINY_ONBOARDING +
						'_' +
						this.subscriptionService.customerData.currentService.siteId,
					true
				);

				this.setTaggingVaribles(this.slidesSet[0]);
			}
		}
		this.showModal = false;
	}
	ngOnInit() {
		this.comparationSubscription = this.recommendationService.comparationModel$.subscribe((comparation: any) => {
			this.slidesSet = [];
			this.titles = [];
			this.description = [];
			this.img = [];

			this.hasUnlimited = false;
			this.hasTV = false;
			this.hasLimited = false;

			if (comparation) {
				Object.keys(comparation).forEach((key) => {
					// Check slide 1 and slide 2 (mobile with unlimited data)
					if (comparation[key].offered) {
						comparation[key].offered.forEach((offer: AttrModel) => {
							// Check if has any data bundle offered with unlimited data
							if (
								offer.type.toLowerCase() === BundleType.Data.toLowerCase() &&
								offer.value.toLowerCase() === isUnlimited.toLowerCase()
							) {
								this.hasUnlimited = true;
							} else if (
								offer.type.toLowerCase() === BundleType.Data.toLowerCase() &&
								offer.value.toLowerCase() !== isUnlimited.toLowerCase()
							) {
								this.hasLimited = true;
							}
						});
					}

					// Check Tv in current products
					if (comparation[key].serviceType.toLowerCase() === ServiceType.Tv.toLowerCase()) {
						this.hasTV = true;
					}
				});

				this.initSlides();
			}
		});
	}

	initSlides() {
		// Calculate result fee
		const currentProduct = this.recommendationService.currentProduct;
		const offer =
			this.recommendationService.offers && this.recommendationService.offers.length > 0
				? this.recommendationService.offers[0]
				: null;
		const currentAmount = currentProduct ? currentProduct.subscriptionsAmount : 0;
		const offeredAmount = offer ? offer.offerTotalAmount : 0;
		this.result_fee = Number(currentAmount) - Number(offeredAmount) + this.recommendationService.keepTotalAmount.fee;

		this.translate.get('migration').subscribe((migration) => {
			if (this.hasUnlimited) {
				this.slidesSet.push(this.Slides.intro);
				// Add Slide 1
				this.titles.push(migration.migrationLandingSlides.itemList.migrationSlideItem1.title);
				this.description.push('');
				this.img.push(this.appservice.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/induccionCover.png'));
				this.buttonText.push(migration.migrationLandingSlides.itemList.migrationSlideItem1.buttonText);
			} else if (this.hasLimited) {
				this.slidesSet.push(this.Slides.know);
				this.slidesSet.push(this.Slides.know);
				// Add Slide 1
				this.titles.push(migration.migrationLandingSlides.itemList.migrationSlideItem4.title);
				this.description.push(migration.migrationLandingSlides.itemList.migrationSlideItem4.description);
				this.img.push(this.appservice.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/induccionCover.png'));
				this.buttonText.push(migration.migrationLandingSlides.itemList.migrationSlideItem4.buttonText);

				// Add Slide 2
				this.titles.push(migration.migrationLandingSlides.itemList.migrationSlideItem5.title);
				this.description.push(migration.migrationLandingSlides.itemList.migrationSlideItem5.description);
				this.img.push(this.appservice.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/branding.png'));
				this.buttonText.push(migration.migrationLandingSlides.itemList.migrationSlideItem5.buttonText);
			}

			if (this.hasTV) {
				this.slidesSet.push(this.Slides.upgrade);
				this.titles.push(migration.migrationLandingSlides.itemList.migrationSlideItem3.title);
				this.description.push(migration.migrationLandingSlides.itemList.migrationSlideItem3.description);
				this.img.push(this.appservice.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/branding.png'));
				this.buttonText.push(migration.migrationLandingSlides.itemList.migrationSlideItem3.buttonText);
			}
		});

		// If there is only one slide, remove pagination
		if (this.img.length <= 1) {
			// this.onBoarding.config.pagination = null;
		}
	}

	clickPrimaryButton() {
		// Migration Tagging
		const eventName = tagging.migration.entryPoint.banner.event_name;
		const data = Object.assign({}, tagging.migration.data);
		this.tagging.track(eventName, data);

		this.router.navigate([routes.config.Recommender.route]);
	}

	ngOnDestroy() {
		this.comparationSubscription.unsubscribe();
	}

	changeSlide(index: number) {
		this.setTaggingVaribles(this.slidesSet[index]);
	}

	private setTaggingVaribles(title: string) {
		const page = Object.assign({}, tagging.migration.page);
		page.page_name = page.page_name.replace('{step}', '2').replace('{title}', title);
		page.page_subcategory_level_1 = page.page_subcategory_level_1.replace('{step}', '2');
		page.page_typology = 'overlay';

		const data = tagging.migration.data;
		data.result_fee = this.result_fee;
		data.help_bubble_status = BubbleStatus.unavailable;

		this.tagging.view(page, data);
	}
}
