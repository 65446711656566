<div
	class="reamin-usage row change-padding cont-height"
	[ngClass]="{ 'no-margin-cont-height': parent.tariffService.Tariff.Yu && parent.isBenifitsActive }"
>
	<div
		class="remain"
		[ngClass]="
			isUnlimited || Bundle.IsUnLimited || (Bundle.TotalConsumption && Bundle.ConsumedConsumption != null)
				? 'col-xs-7'
				: 'col-xs-12'
		"
	>
		<ng-container *ngIf="!isUnlimited">
			<span *ngIf="!Bundle.isShared" class="bundle-name-lines">
				<span *ngIf="Bundle.Name"> {{ Bundle.Name }} </span>
				<span *ngIf="!Bundle.Name && Bundle.title"> {{ Bundle.title }}&nbsp;</span>
				<span *ngIf="!Bundle.Name && Bundle.title"> {{ Bundle.titleLimit }} {{ Bundle.titleRate }} </span>
			</span>
			<p *ngIf="showYuWeekendText" class="sub-text_blue" id="SharedLandingDataBundleYuWeekendText">
				{{ 'v10.productsServices.misc.yuIncluded' | translate }}
			</p>
			<div *ngIf="Bundle.isShared && Bundle.sharingDataType === parent.dataSharingTypes.owner">
				{{ Bundle.Name.split(',').length > 1 ? Bundle.Name.split(',')[0] : Bundle.Name }}
			</div>
			<div *ngIf="Bundle.isShared && Bundle.sharingDataType === parent.dataSharingTypes.member">
				{{ Bundle.Name.split(',').length > 1 ? Bundle.Name.split(',')[0] : Bundle.Name }}
			</div>
			<div [ngClass]="{ 'owner-data-sharing': Bundle.isShared }" *ngIf="Bundle.isShared">
				{{ Bundle.Name.split(',').length > 1 ? Bundle.Name.split(',')[1] : Bundle.Name }}
			</div>
		</ng-container>
		<ng-container *ngIf="isUnlimited">
			<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/data_icon.png" />
			<span *ngIf="!Bundle.isShared"> {{ 'productsServices.rgk.item.data.title' | translate }} </span>
		</ng-container>
	</div>
	<ng-container *ngIf="!isUnlimited">
		<div
			class="col-xs-5 usage"
			*ngIf="!Bundle.IsUnLimited && Bundle.TotalConsumption && Bundle.ConsumedConsumption != null"
		>
			<sp-landing-percentage
				title="{{ 'productsServices.itemsList.psRemianing.body' | translate }}"
				[unit]="Bundle.RemainConsumptionUnit"
				[remaining]="parent.unitFormate(Bundle.RemainConsumptionData)"
				[percentage]="Bundle.RemainingConsumptionPercentage"
			>
			</sp-landing-percentage>
		</div>
		<div class="col-xs-5 usage" *ngIf="Bundle.IsUnLimited">
			<sp-landing-percentage [percentage]="100"> </sp-landing-percentage>
		</div>
	</ng-container>
	<ng-container *ngIf="isUnlimited">
		<div class="col-xs-5 usageDestiny">
			<span> {{ 'productsServices.rgk.item.data.description' | translate }} </span>
		</div>
	</ng-container>
</div>
