import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../app.service';
import { SubscriptionService } from '../core/services/subscription.service';
import * as constants from '../shared/constants/defines';
import { BillChargesType, BillType } from '../shared/enums/bill-type.enum';
import { BundleStatus } from '../shared/enums/bundleStatus.enum';
import { ProductType } from '../shared/enums/productType.enum';
import { Discount, PackagesDesc, SBA, SVAAmount, SVAs } from '../shared/models/SVAS.model';
import { AdditionalCharges, AggregatedAmount, Amount, Coute, GetCoutesResponse } from '../shared/models/coute.model';
import { PurchaseProductsData } from './purchase-products-data';

@Injectable()
export class PurchaseProductsService {
	public coutes: Array<Coute>;
	public aggregatedAmount = new AggregatedAmount();
	public svas: Array<SVAs>;
	/* Cambio PO */
	public svas2: Array<SVAs>;
	/* Fin Cambio PO */
	public selectedService: SVAs;
	public svaProductType: SVAs;
	navigate: boolean = false;
	showLoader: boolean = false;
	constructor(
		private purchaseProductsData: PurchaseProductsData,
		private subscriptionService: SubscriptionService,
		private translate: TranslateService,
		private appTheme: AppService
	) {}

	GetCoutes(customerAccountId: string, isFinancing?: boolean, taxCalculation?: boolean): Observable<Array<Coute>> {
		this.coutes = new Array();
		return this.purchaseProductsData.GetCoutes(customerAccountId, isFinancing, taxCalculation).pipe(
			map((res: GetCoutesResponse) => {
				const items: Coute[] = res.items || [];
				items.map((item) => {
					this.coutes.push(this.mapCoutesData(item));
				});
				this.coutes = this.generateJsonCoutes(this.coutes);
				const aggregatedAmount: AggregatedAmount = res.aggregatedAmount;
				this.aggregatedAmount = this.mapAggregatedAmount(aggregatedAmount);
				return this.coutes;
			})
		);
	}

	/**map billing accounts data */
	mapCoutesData(item: Coute): Coute {
		const coute = new Coute();
		coute.type = item.type || null;
		coute.name = item.name || null;
		coute.desc = item.desc || null;
		coute.isBundle = item.isBundle || null;
		const amount = item.amount || null;
		if (amount) {
			coute.amount = new Amount();
			coute.amount.currency = amount.currency || null;
			coute.amount.taxesCode = amount.taxesCode || null;
			coute.amount.netAmountDue = amount.netAmountDue || null;
			coute.amount.grossAmountDue = amount.grossAmountDue || null;
		}
		coute.additionalCharges = new Array<AdditionalCharges>();
		const additionalCharges: AdditionalCharges[] = item.additionalCharges || null;
		if (additionalCharges) {
			additionalCharges.map((charge) => {
				coute.additionalCharges.push(this.mapAdditionalCharges(charge));
			});
		}
		return coute;
	}

	mapAdditionalCharges(item: AdditionalCharges): AdditionalCharges {
		const additionalCharges = new AdditionalCharges();

		additionalCharges.type = item.type || null;
		additionalCharges.desc = item.desc || null;
		additionalCharges.terminationDate = item.terminationDate || null;
		const amountAdditionalCharges = item.amount || null;
		if (amountAdditionalCharges) {
			additionalCharges.amount = new Amount();
			additionalCharges.amount.currency = amountAdditionalCharges.currency || null;
			additionalCharges.amount.taxesCode = amountAdditionalCharges.taxesCode || null;
			additionalCharges.amount.netAmountDue = amountAdditionalCharges.netAmountDue || null;
			additionalCharges.amount.grossAmountDue = amountAdditionalCharges.grossAmountDue || null;
		}

		return additionalCharges;
	}

	mapAggregatedAmount(item: AggregatedAmount): AggregatedAmount {
		const aggregatedAmount = new AggregatedAmount();

		aggregatedAmount.type = item.type || null;
		aggregatedAmount.taxesCode = item.taxesCode || null;
		aggregatedAmount.currency = item.currency || null;
		aggregatedAmount.grossAmountDue = item.grossAmountDue || null;
		aggregatedAmount.netAmountDue = item.netAmountDue || null;
		return aggregatedAmount;
	}

	generateJsonCoutes(coutes: Coute[]): Coute[] {
		const coutesList: Array<Coute> = [];
		const otherDiscount: Array<Coute> = [];
		coutes.forEach((item) => {
			const auxCoute: Coute = {};
			auxCoute['name'] = item.name;
			auxCoute['type'] = item.type;
			auxCoute['amounts'] = [];
			if ((!item.isBundle || item.type === BillType.bundle) && item.amount) {
				auxCoute['amounts'].push({
					desc: item.desc || '',
					netAmountDue: item.amount.netAmountDue,
					grossAmountDue: item.amount.grossAmountDue,
					currency: item.amount.currency,
				});
			}

			item.additionalCharges.forEach((charges) => {
				auxCoute['amounts'].push({
					desc: charges.desc || '',
					netAmountDue: charges.amount.netAmountDue,
					grossAmountDue: charges.amount.grossAmountDue,
					currency: charges.amount.currency,
					endDate:
						charges.type === BillChargesType.discount ? this.parseTerminationDate(charges.terminationDate) : null,
				});
			});

			if (auxCoute['amounts'].length > 0) {
				if (item.type === BillType.other) {
					otherDiscount.push(auxCoute);
				} else {
					coutesList.push(auxCoute);
				}
			}
		});

		if (otherDiscount.length > 0) {
			const auxCoute: Coute = {};
			auxCoute['name'] = otherDiscount[0].name;
			auxCoute['type'] = otherDiscount[0].type;
			auxCoute['amounts'] = [];
			otherDiscount.forEach((discount) => {
				discount.amounts.forEach((amount) => {
					auxCoute['amounts'].push(amount);
				});
			});
			coutesList.push(auxCoute);
		}

		return coutesList;
	}

	parseTerminationDate(terminationDate: AdditionalCharges['terminationDate']): string {
		if (!terminationDate) {
			return null;
		}
		const endDate: moment.Moment = moment(terminationDate * 1000);
		if (
			endDate.get('year') >= constants.maxYearToConsiderPermanentDiscount ||
			endDate.get('year') <= constants.minYearToConsiderPermanentDiscount
		) {
			return null;
		}
		return endDate.format('DD/MM/YYYY');
	}

	getSVAs() {
		this.svas = new Array<SVAs>();
		return this.purchaseProductsData
			.GetSVAs(this.subscriptionService.customerData ? this.subscriptionService.customerData.customerAccountsId : null)
			.pipe(
				// jsonpath refactor: added 'any' type due to missing model
				map((res: any) => {
					const items = res.items || [];
					items.map((item) => {
						this.svas.push(this.mapSVAsData(item));
					});
				})
			);
	}
	getSVAsByProductType(productType: ProductType) {
		this.svaProductType = new SVAs();
		return this.purchaseProductsData
			.GetSVAsByType(
				this.subscriptionService.customerData ? this.subscriptionService.customerData.customerAccountsId : null,
				productType
			)
			.pipe(
				// jsonpath refactor: added 'any' type due to missing model
				map((res: any) => {
					const item = res.items?.[0];
					this.svaProductType = this.mapSVAsData(item);
					this.svaProductType.sba.forEach((sba: SBA) => {
						if (!sba.subscription.status) {
							sba.subscription.status = BundleStatus.Active;
						}
					});
				})
			);
	}
	/* Cambio PO */
	getSolucionProfesional() {
		this.svas2 = new Array<SVAs>();
		return this.purchaseProductsData
			.GetSVAs(this.subscriptionService.customerData ? this.subscriptionService.customerData.customerAccountsId : null)
			.pipe(
				// jsonpath refactor: added 'any' type due to missing model
				map((res: any) => {
					const solucionProfesional = res.solucionProfesional;
					solucionProfesional.map((item) => {
						this.svas2.push(this.mapSVAsData(item));
					});
				})
			);
	}
	/* Fin Cambio PO */
	mapSVAsData(item: any) {
		// jsonpath refactor: added 'any' type due to missing response model, very similar to SVAs model
		const sva = new SVAs();
		sva.code = item.code || null;
		sva.name = item.name || null;
		sva.category = item.category || null;
		sva.resumeIcon = this.getIcon(item.resumeIcon) || null;
		sva.detailIcon = this.getIcon(item.detailIcon) || null;
		sva.info = item.info || null;
		sva.longDesc = item.desc?.long || null;
		sva.shortDesc = item.desc?.short || null;
		sva.additionalInfo = item.additionalInfo || null;
		sva.deviceName = item.deviceName || null;
		sva.type = item.type || null;
		const amount = item.amount || null;
		const packagesDesc = item.packagesDesc || null;

		if (packagesDesc) {
			sva.packagesDesc = new Array<PackagesDesc>();
			sva.packagesDesc = packagesDesc;
		}
		sva.amount = new SVAAmount();
		sva.amount = this.fillAmount(amount);
		const discount = item.discount || null;
		if (discount) {
			sva.discount = new Discount();
			sva.discount.amount = new SVAAmount();
			const discountAmount = discount.amount || null;
			sva.discount.amount = this.fillAmount(discountAmount);
			sva.discount.terminationDate = discount.terminationDate || null;
		}
		sva.sba = new Array<SBA>();
		sva.sba = (item.sba as Array<SBA>) || null;

		sva.status = item.status || null;
		sva.typeOnePlus = item.typeOnePlus || null;
		return sva;
	}
	fillAmount(data) {
		const amount = new SVAAmount();
		if (data) {
			amount.currency = data.currency || null;
			amount.taxesCode = data.taxesCode || null;
			amount.netAmountDue = data.netAmountDue || 0;
			amount.grossAmountDue = data.grossAmountDue || 0;
			amount.taxesDue = data.taxesDue || null;
			amount.amountCredited = data.amountCredited || null;
			amount.chargedFor = data.chargedFor || null;
			amount.type = new Array<string>();
			amount.type = data.type || null;
		}
		return amount;
	}

	getIcon(path: string) {
		let image;
		this.translate.get(path).subscribe((data) => {
			if (path === data) {
				image = 'https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/icon-generic-sva.png';
			} else {
				image = this.appTheme.getImgFullPath(data);
			}
		});
		return image;
	}
}
