<div
	class="top-up-rows"
	[ngClass]="{
		'forward-transition-tray': trayService?.showForwardTransition,
		'backward-transition-tray': trayService?.showBackwardTransition,
		'top-up-rows-responsive': topupService.useDynamicTray,
		'height-with-percentage': topupService.useDynamicTray,
		'height-with-vh': !topupService.useDynamicTray
	}"
>
	<div class="top-up-header" [ngClass]="{ 'hide-title': topupService.useDynamicTray }">
		<img class="header-img" src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/red-small@2x.png" />
		<div class="top-up-title">
			<p>{{ 'common.itemsList.refills.body' | translate }}</p>
		</div>
		<div class="exit-logo" *ngIf="topupService.isMobileView">
			<span *ngIf="topupService.isMobileView" (click)="closeTrayWithTagging()" class="icon icon-close"> </span>
		</div>
	</div>
	<div class="ticket-container">
		<canvas class="ticket-canvas" #ticketCanvas> </canvas>
		<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/ticket@2x.png" class="ticket-img" />
		<div class="ticket-header">
			<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/ic-tick-hifi-light-theme@2x.png" alt="" class="payment-img" />
			<p>{{ ticketTitle }}</p>
		</div>
		<div class="ticket-details">
			<div class="detail-item" *ngFor="let item of ticketDetails">
				<p class="title">{{ item.title }}</p>
				<p class="text">{{ item.text }}</p>
			</div>
		</div>
	</div>
	<div class="ticket-buttons-container">
		<button type="button" class="continue button red two" (click)="downLoadTicket()">
			{{ 'payment.buttonList.downloadAsImageButton.text' | translate }}
		</button>
		<button *ngIf="topupService.showSecondCTA" type="button" class="cancel button white two" (click)="closeTray()">
			{{ 'payment.buttonList.closeButton.text' | translate }}
		</button>
	</div>
</div>

<div
	class="toast-download"
	[ngClass]="{
		'open-animation-toast': showDownloadedToast,
		'close-animation-toast': !showDownloadedToast,
		'show-download-toast': !hideDownloadedToast,
		'hide-download-toast': hideDownloadedToast
	}"
>
	<div [ngClass]="{ 'close-animation-text-fade-out': !showDownloadedToast }">
		<canvas #tickCanvas class="tick-canvas"> </canvas>
		<p>{{ 'payment.itemsList.successfulDownloadPhotoDebt.body' | translate }}</p>
	</div>
</div>
