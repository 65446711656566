import { CardImageSelectorModel } from '@mva10/mva10-angular';
import { Contract } from '../../my-account/contract.model';
import { FaultManagmentTicketType } from '../enums/fault-managmenet-ticket-type.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { TariffStatus } from '../enums/tariffStatus.enum';
import { ServicePackageModel } from './service-package.model';

export class ServiceModel {
	id?: string;
	dataSharingType?: string;
	msisdn?: string;
	type?: ServiceType;
	siteDigital?: boolean;
	serviceDigital?: boolean;
	onePlus?: boolean;
	onePlusMicro?: boolean;
	name?: string;
	desc?: string;
	status?: TariffStatus;
	package?: ServicePackageModel;
	familia?: ServicePackageModel;
	siteId?: string;
	serviceContracts?: Contract[];
	tarrifCode?: string;
	currentConsumption?: any;
	vovSecondMessageViewed?: boolean;
	segment?: string;
	overdue?: boolean;
	clientStatus?: string;
	siteType?: string;
	ppeauth?: any;
	internetSpeed?: string;
	hasPendingPciTransactions?: boolean;
	icon?: string;
	showPlus?: boolean;
	additionalInfo?: ServiceAdditionalInfo;
	serviceCategory?: FaultManagmentTicketType;
	hasOnGoingOrder?: boolean;
	hasBlueLabelOpenTicket?: boolean;
	hasBlueLabelClosedTicket48Hrs?: boolean;
	disabled?: boolean;
	hasOpenTicket?: boolean;
	hasClosedTicket?: boolean;
	openTicketId?: string;
	instance?: string;
	smartPay?: boolean;
	ilimitada?: boolean;
}

export class ServiceAdditionalInfo {
	tariffName: string;
	isActiveBenefit: boolean;
	benefitExpDate: string;
	benefitRenewalDate: string;
	creditValue: string;
	creditCurrency: string;
	monthlyRecurring: string;
	monthlyRecurringCurrency: string;
	hasBenefits: boolean;
	msisdn: string;
	constructor() {
		this.tariffName = '';
		this.benefitExpDate = '';
		this.benefitRenewalDate = '';
		this.creditCurrency = '';
		this.creditValue = '';
		this.monthlyRecurring = '';
	}
}

export declare class TypesCardImageSelectorMode {
	MOBILEPOSTPAID: CardImageSelectorModel;
	MOBILEPREPAID: CardImageSelectorModel;
	MBBPOSTPAID: CardImageSelectorModel;
	MBBPREPAID: CardImageSelectorModel;
	MBBHOLIDAY: CardImageSelectorModel;
	LANDLINE: CardImageSelectorModel;
	VODAFONEENTUCASA: CardImageSelectorModel;
	TV: CardImageSelectorModel;
	TVONLINE: CardImageSelectorModel;
	ADSL: CardImageSelectorModel;
	FIBRE: CardImageSelectorModel;
}

export class ServiceTransformed {
	id: string;
	type: string;
	text: string;
	ico: string;
}

export class TypesServiceTransformed {
	MOBILEPOSTPAID: ServiceTransformed;
	MOBILEPREPAID: ServiceTransformed;
	MBBPOSTPAID: ServiceTransformed;
	MBBPREPAID: ServiceTransformed;
	LANDLINE: ServiceTransformed;
	VODAFONEENTUCASA: ServiceTransformed;
	TV: ServiceTransformed;
	ADSL: ServiceTransformed;
	FIBRE: ServiceTransformed;
}

export class ServiceResponse {
	code?: string;
	description?: string;
	digital?: boolean;
	id?: string;
	instance?: string;
	name?: string;
	links?: Object;
	type?: string;
	parts?: Object;
}
export class CardServiceImageSelectorModel extends CardImageSelectorModel {
	disabled: boolean;
	goToTicket: boolean;
}
