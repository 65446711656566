import { Injectable } from '@angular/core';
import { SidemenuQuery, darkMode, lightMode } from '../constants/defines';
import { CtcOverlayModel } from '../models/ctc-overlay.model';
import { DmpModel, NexusDmpModel, NexusRecoDmpModel } from '../models/dmp.model';
import { UtilitiesService } from '../utils/utilities.service';

@Injectable()
export class DmpService {
	public window: any = window;

	constructor(private utilsService: UtilitiesService) {
		this.window._personalizacion = {};
		this.window._personalizacion = {
			dashboardBanner1: {
				resolve: null,
				reject: null,
				promise: null,
			},
			dashboardBanner2: {
				resolve: null,
				reject: null,
				promise: null,
			},
			tiendaBanner1: {
				resolve: null,
				reject: null,
				promise: null,
			},
			tiendaBanner2: {
				resolve: null,
				reject: null,
				promise: null,
			},
			nexusBanner: {
				resolve: null,
				reject: null,
				promise: null,
			},
			newLinesPurchaseSticky: {
				resolve: null,
				reject: null,
				promise: null,
			},
			dashboardDescubre: {
				resolve: null,
				reject: null,
				promise: null,
			},
			s4yBanner1: {
				resolve: null,
				reject: null,
				promise: null,
			},
			s4yCarrusel1: {
				resolve: null,
				reject: null,
				promise: null,
			},
			s4yListado1: {
				resolve: null,
				reject: null,
				promise: null,
			},
			s4ylistadoBeneficios: {
				resolve: null,
				reject: null,
				promise: null,
			},
			lineasAdicionalesHome: {
				resolve: null,
				reject: null,
				promise: null,
			},
			flujoConvergencia: {
				resolve: null,
				reject: null,
				promise: null,
			},
		};
		this.initPromiseDmp();
	}

	get personalizacion() {
		return this.window._personalizacion;
	}

	initPromiseDmp() {
		this.window._personalizacion.dashboardBanner1.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.dashboardBanner1.resolve = resolve;
			this.window._personalizacion.dashboardBanner1.reject = reject;
		});
		this.window._personalizacion.dashboardBanner2.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.dashboardBanner2.resolve = resolve;
			this.window._personalizacion.dashboardBanner2.reject = reject;
		});
	}

	public initNewLinesPurchaseSticky(): void {
		this.window._personalizacion.newLinesPurchaseSticky.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.newLinesPurchaseSticky.resolve = resolve;
			this.window._personalizacion.newLinesPurchaseSticky.reject = reject;
		});
	}

	public initDashboardDiscovery(): void {
		this.window._personalizacion.dashboardDescubre.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.dashboardDescubre.resolve = resolve;
			this.window._personalizacion.dashboardDescubre.reject = reject;
		});
	}

	public initLineasAdicionalesHome(): void {
		this.window._personalizacion.lineasAdicionalesHome.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.lineasAdicionalesHome.resolve = resolve;
			this.window._personalizacion.lineasAdicionalesHome.reject = reject;
		});
	}

	initPromiseShopBanner() {
		this.window._personalizacion.tiendaBanner1.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.tiendaBanner1.resolve = resolve;
			this.window._personalizacion.tiendaBanner1.reject = reject;
		});
		this.window._personalizacion.tiendaBanner2.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.tiendaBanner2.resolve = resolve;
			this.window._personalizacion.tiendaBanner2.reject = reject;
		});
	}

	initPromisesS4Y(): void {
		this.window._personalizacion.s4yBanner1.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.s4yBanner1.resolve = resolve;
			this.window._personalizacion.s4yBanner1.reject = reject;
		});
		this.window._personalizacion.s4yCarrusel1.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.s4yCarrusel1.resolve = resolve;
			this.window._personalizacion.s4yCarrusel1.reject = reject;
		});
		this.window._personalizacion.s4yListado1.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.s4yListado1.resolve = resolve;
			this.window._personalizacion.s4yListado1.reject = reject;
		});
		this.window._personalizacion.s4ylistadoBeneficios.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.s4ylistadoBeneficios.resolve = resolve;
			this.window._personalizacion.s4ylistadoBeneficios.reject = reject;
		});
	}

	public initPromisesBundle(): void {
		this.window._personalizacion.flujoConvergencia.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.flujoConvergencia.resolve = resolve;
			this.window._personalizacion.flujoConvergencia.reject = reject;
		});
	}

	/**
	 * @param res JSON from DMP
	 */
	public dmpContent(res: any): DmpModel {
		const model = new DmpModel();
		const animation = res.animation;
		model.animation = animation ? JSON.parse(animation) : '';
		model.bgColor = res.bgColor || '';
		model.buttonType = res.buttonType || '';
		const controlGroup: string = res.controlGroup;
		model.controlGroup = controlGroup ? JSON.parse(controlGroup) : false;
		model.CTAbgColor = res.CTAbgColor || '';
		model.CTAtextColor = res.CTAtextColor || '';
		model.deeplink = res.deeplink;
		const design = res.design || '';
		model.design = design.toLowerCase();
		model.entryPointTitle = res.entryPointTitle || '';
		model.entryPointLocation = res.entryPointLocation || '';
		model.experienceName = res.experienceName || '';
		model.gradient = res.gradient || '';
		model.image = res.image;
		model.image_rsp = res.image_rsp;
		model.imageTitle = res.imageTitle;
		model.imageTitle_rsp = res.imageTitle_rsp;
		model.journeyName = res.journeyName || '';
		model.nameCTA = res.nameCTA || '';
		const padding: string = res.padding;
		model.padding = padding ? JSON.parse(padding) : false;
		model.promo = res.promo || '';
		model.text = res.text || '';
		model.textCTA = res.textCTA || '';
		model.title = res.title || '';
		model.webviewlink = res.webviewlink;
		model.imgRight = res.image_right || '';
		model.imgRightRsp = res.image_right_rsp || '';
		model.paddingImg = res.padding_image_right || false;
		model.darkTextFlag = (res.mode || darkMode) === lightMode;

		if (res.ctc?.num) {
			model.ctc = new CtcOverlayModel();
			model.ctc.number = res.ctc.num;
			model.ctc.text = res.ctc.text;
			model.ctc.buttonText = res.ctc.textCTA;
			model.ctc.title = res.ctc.title;
		}

		return model;
	}

	/**
	 * @param res Array JSON from DMP
	 */
	public arrayDmpContent(res: Array<{ [key: string]: string }>): Array<DmpModel> {
		const arrayDmpModel: DmpModel[] = [];
		res.forEach((element) => {
			arrayDmpModel.push(this.dmpContent(element));
		});

		return arrayDmpModel;
	}

	public initPromisesNexusBanner(): void {
		this.window._personalizacion.nexusBanner.promise = new Promise((resolve, reject) => {
			this.window._personalizacion.nexusBanner.resolve = resolve;
			this.window._personalizacion.nexusBanner.reject = reject;
		});
	}

	public arrayNexusDmpContent(res: Array<NexusRecoDmpModel>, experienceName: string): Array<NexusDmpModel> {
		const arrayNexusDmpModel: NexusDmpModel[] = [];
		res.forEach((element) => {
			if (element.recomendations.length > 1) {
				this.utilsService.sortByKeyReverse(element.recomendations, SidemenuQuery.order);
			}
			arrayNexusDmpModel.push(this.DmpNexusContent(element.code, element.recomendations[0], experienceName));
		});

		return arrayNexusDmpModel;
	}

	public DmpNexusContent(code: string, res: NexusDmpModel, experienceName: string): NexusDmpModel {
		const model: NexusDmpModel = new NexusDmpModel();
		model.code = code;
		model.cardTitle = res.cardTitle;
		model.cardDesc = res.cardDesc;
		model.cardImgDesktop = res.cardImgDesktop;
		model.cardImgMobile = res.cardImgMobile;
		model.cardBackgroundImgDesktop = res.cardBackgroundImgDesktop;
		model.cardBackgroundImgMobile = res.cardBackgroundImgMobile;
		model.cardBackgroundColour = res.cardBackgroundColour;
		model.cardBtnText = res.cardBtnText;
		model.cardBtnColour = res.cardBtnColour;
		model.cardBtnBackground = res.cardBtnBackground;
		model.deeplink = res.deeplink;
		model.order = res.order;
		model.controlGroup = res.controlGroup;
		model.journeyName = res.journeyName;
		model.experienceName = experienceName;
		return model;
	}
}
