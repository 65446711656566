export class TicketModel {
	public averia?: string;
	public ticketId: string;
	public ticketName?: string;
	public ticketType?: string;
	public status?: string;
	public creationDate?: string;
	public closingDate?: string;
	public title?: string;
	public index?: string;
	public icon?: string;
	public isGeneralBreakDown?: boolean;
	public showTicketDetail?: boolean = false;
	public showCloseOpen?: boolean = false;
	public template: TicketTemplateModel;
	public caseBE?: {
		caseId?: string;
		status?: string;
		channelId?: string;
		agentName?: string;
		postId?: string;
		createdAt?: string;
		statusUpdatedAt?: string;
	};
	public journey?: string;
	public serviceId?: string;
	public chatRecorded?: ChatRecorded[];
	public attachment?: Attachment[];
}

export class TicketDetailModel {
	defaultTicketDetailTitleText?: string;
	defaultTicketDetailButtonText?: string;
	ticketDetailContentButtonText?: string;
	ticketDetailTitleText?: string;
	ticketDetailButtonText?: string;
	ticketDetailBackdropTitleText?: string;
	ticketDetailBackdropSubtitleText?: string;
	ticketDetailBackdropButtonPositive?: string;
	ticketDetailBackdropButtonNegative?: string;
}

export class TicketTemplateModel {
	templateId?: string;
	templateCode?: string;
	templateDate?: string;
	visitFrom?: string;
	visitTo?: string;
	outSLA?: boolean;
}

export class Attachment {
	id?: string;
	href?: string;
	attachmentType?: string;
	content?: string;
	description?: string;
	mimeType?: string;
	name?: string;
	url?: string;
	size?: string;
	validFor?: string;
}

export interface ChatRecorded {
	isOwn: boolean;
	receivedAt: string;
	messageContent: string;
}
