import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { BillingService } from '../../../billing/billing.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { SpModalFullComponent } from '../../components/sp-modal-full/sp-modal-full.component';
import { BIZTALKERROR, callOptionAction } from '../../constants/defines';
import { callIdStatus } from '../../enums/callIdStatus.enum';
import { CrossFunctionalityService } from '../../services/cross-functionality.service';
import { ProductService } from '../../services/product.service';

@Component({
	selector: 'sp-activation-toggle-card',
	templateUrl: './sp-activation-toggle-card.component.html',
	styleUrls: ['./sp-activation-toggle-card.component.scss'],
})
export class SpActivationToggleCardComponent implements OnInit {
	@Input() public firstLine: string;
	@Input() public secondLine: string;
	@Input() public stauts: string;
	stautsText: string;
	green: boolean;
	red: boolean;
	yellow: boolean;
	showToggle: boolean;
	checkToggle: boolean;
	wcsText: SafeHtml;
	public isOnePlus: boolean;
	@ViewChild('fullModal', { static: true }) fullModal: SpModalFullComponent;
	@Input() public serviceNumber;
	showConfigurationSpinner: boolean;
	@Input() public ispendingSBA: boolean = false;
	@Input() public isBilling: boolean = true;
	@Input() public productCode: any;
	constructor(
		private translateService: TranslateService,
		public cross: CrossFunctionalityService,
		public billingService: BillingService,
		private appService: AppService,
		private tagging: TaggingHelperService,
		private sanitizer: DomSanitizer,
		private productService: ProductService,
		private utilitiesService: UtilitiesService
	) {}

	ngOnInit() {
		// to bring text of wcs of status
		this.stautsText = '';
		this.drawComponent();
		this.isOnePlus = this.utilitiesService.getCurrentSiteServices()[0].onePlus;
	}

	changeStatus(statuschecked) {
		this.cross.showToast = false;
		if (this.isBilling) {
			this.changeBilling(statuschecked);
		} else {
			this.changeProduct(statuschecked);
		}
	}

	drawComponent() {
		this.translateService.get('common.itemsList').subscribe((data) => {
			switch (this.stauts.toLowerCase()) {
				case callIdStatus.active.toLowerCase():
					this.green = true;
					this.red = false;
					this.yellow = false;
					this.showToggle = true;
					this.checkToggle = true;
					this.stautsText = data.CallIDActiveStatus.body;
					break;
				case callIdStatus.inactive.toLowerCase():
					this.green = false;
					this.red = true;
					this.yellow = false;
					this.showToggle = true;
					this.checkToggle = false;
					this.stautsText = data.CallIDInActiveStatus.body;
					break;
				case callIdStatus.pendingActivation.toLowerCase():
					this.green = false;
					this.red = false;
					this.yellow = true;
					this.showToggle = false;
					this.stautsText = data.CallIDPendingActivationStatus.body;

					break;
				case callIdStatus.pendingDeactivation.toLowerCase():
					this.green = false;
					this.red = false;
					this.yellow = true;
					this.showToggle = false;
					this.stautsText = data.CallIDPendingDeactivationStatus.body;

					break;
			}
		});
	}

	changeBilling(statuschecked) {
		this.checkToggle = statuschecked;
		const serviceId = this.serviceNumber;

		this.fullModal.show();
		this.translateService.get('common.itemsList').subscribe((lang) => {
			if (this.stauts.toLowerCase() === callIdStatus.inactive.toLowerCase()) {
				this.tagging.track(
					tagging.bill.callOptions.clickCallIdToggleActive.eventName,
					tagging.bill.callOptions.clickCallIdToggleActive.data
				);

				this.cross.showToast = false;
				// tagging
				tagging.bill.callOptions.callIDJourney.firstStep.data.journey_subcategory = callOptionAction.activar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.firstStep.eventName,
					tagging.bill.callOptions.callIDJourney.firstStep.data
				);
				tagging.bill.callOptions.callIDJourney.secondStep.data.journey_subcategory = callOptionAction.activar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.secondStep.eventName,
					tagging.bill.callOptions.callIDJourney.secondStep.data
				);

				this.showConfigurationSpinner = false;
				this.fullModal.headerTitle = lang.inactiveCallIdOverlayTitle.body;
				this.fullModal.imgSrc = this.appService.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/call-or-minutes-hi@2x.png');
				if (this.isOnePlus) {
					this.wcsText = this.sanitizer.bypassSecurityTrustHtml(
						lang.inactiveCallIdOverlayOnePlusDesc.body.replace('{0}', serviceId)
					);
				} else {
					this.wcsText = this.sanitizer.bypassSecurityTrustHtml(
						lang.inactiveCallIdOverlayDesc.body.replace('{0}', serviceId)
					);
				}
				this.fullModal.bodyContentSafeHtml = this.wcsText;
				this.fullModal.primaryButtonText = lang.inactiveCallIdOverlayConfirmBtn.body;
				this.fullModal.primaryButtonClick = this.fullModal.primaryButtonClick = () => {
					this.cross.showNudge = false;
					this.showConfigurationSpinner = true;
					this.billingService.updateCallIDstatus(this.stauts, callIdStatus.active, serviceId).subscribe(
						() => {
							tagging.bill.callOptions.callIDJourney.success.data.journey_subcategory = callOptionAction.activar;
							this.tagging.track(
								tagging.bill.callOptions.callIDJourney.success.eventName,
								tagging.bill.callOptions.callIDJourney.success.data
							);
							this.stauts = callIdStatus.pendingActivation;
							this.stautsText = lang.CallIDPendingActivationStatus.body;
							this.yellow = true;
							this.showToggle = false;
							this.fullModal.hide();
							this.cross.toastMessage = lang.deactivationSucessToast.body;
							this.cross.showToast = true;
						},
						(error) => {
							this.fullModal.hide();
							if (error.error && error.error.ecode && error.error.ecode.toString() === BIZTALKERROR) {
								this.appService.NudgeBiztalkError();
								this.tagging.taggingBiztalkNudgeError();
							}
						}
					);
				};
				this.fullModal.secondaryButtonStyle = 'btn-gray';
				this.fullModal.secondaryButtonText = lang.inactiveCallIdOverlayCancelBtn.body;
				this.fullModal.secondaryButtonClick = () => {
					this.fullModal.hide();
				};
			}
			if (this.stauts.toLowerCase() === callIdStatus.active.toLowerCase()) {
				this.tagging.track(
					tagging.bill.callOptions.clickCallIdToggleDesActive.eventName,
					tagging.bill.callOptions.clickCallIdToggleDesActive.data
				);
				tagging.bill.callOptions.callIDJourney.firstStep.data.journey_subcategory = callOptionAction.desactivar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.firstStep.eventName,
					tagging.bill.callOptions.callIDJourney.firstStep.data
				);
				tagging.bill.callOptions.callIDJourney.secondStep.data.journey_subcategory = callOptionAction.desactivar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.secondStep.eventName,
					tagging.bill.callOptions.callIDJourney.secondStep.data
				);
				this.cross.showToast = false;
				this.showConfigurationSpinner = false;
				this.fullModal.headerTitle = lang.activeCallIdOverlayTitle.body;
				this.fullModal.imgSrc = this.appService.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/call-or-minutes-hi@2x.png');
				this.wcsText = this.sanitizer.bypassSecurityTrustHtml(
					lang.activeCallIdOverlayDesc.body.replace('{0}', serviceId)
				);
				this.fullModal.bodyContentSafeHtml = this.wcsText;
				this.fullModal.primaryButtonText = lang.activeCallIdOverlayConfirmBtn.body;
				this.fullModal.primaryButtonClick = this.fullModal.primaryButtonClick = () => {
					this.cross.showNudge = false;
					this.showConfigurationSpinner = true;
					this.billingService.updateCallIDstatus(this.stauts, callIdStatus.inactive, serviceId).subscribe(
						() => {
							this.stauts = callIdStatus.pendingDeactivation;
							this.stautsText = lang.CallIDPendingDeactivationStatus.body;
							this.yellow = true;
							this.showToggle = false;
							this.fullModal.hide();
							tagging.bill.callOptions.callIDJourney.success.data.journey_subcategory = callOptionAction.desactivar;
							this.tagging.track(
								tagging.bill.callOptions.callIDJourney.success.eventName,
								tagging.bill.callOptions.callIDJourney.success.data
							);
							this.cross.toastMessage = lang.deactivationSucessToast.body;
							this.cross.showToast = true;
						},
						(error) => {
							this.fullModal.hide();
							if (error.error && error.error.ecode && error.error.ecode.toString() === BIZTALKERROR) {
								this.appService.NudgeBiztalkError();
								this.tagging.taggingBiztalkNudgeError();
							}
						}
					);
				};
				this.fullModal.secondaryButtonStyle = 'btn-gray';
				this.fullModal.secondaryButtonText = lang.activeCallIdOverlayCancelBtn.body;
				this.fullModal.secondaryButtonClick = () => {
					this.fullModal.hide();
				};
			}
		}); // end of translate
	}

	changeProduct(statuschecked) {
		this.checkToggle = statuschecked;
		const serviceId = this.serviceNumber;

		this.fullModal.show();
		this.translateService.get('common.itemsList').subscribe((lang) => {
			if (this.stauts.toLowerCase() === callIdStatus.inactive.toLowerCase()) {
				this.tagging.track(
					tagging.bill.callOptions.clickCallIdToggleActive.eventName,
					tagging.bill.callOptions.clickCallIdToggleActive.data
				);

				this.cross.showToast = false;
				// tagging
				tagging.bill.callOptions.callIDJourney.firstStep.data.journey_subcategory = callOptionAction.activar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.firstStep.eventName,
					tagging.bill.callOptions.callIDJourney.firstStep.data
				);
				tagging.bill.callOptions.callIDJourney.secondStep.data.journey_subcategory = callOptionAction.activar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.secondStep.eventName,
					tagging.bill.callOptions.callIDJourney.secondStep.data
				);

				this.showConfigurationSpinner = false;
				this.fullModal.headerTitle = lang.inactiveCallIdOverlayTitle.body;
				this.fullModal.imgSrc = this.appService.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/call-or-minutes-hi@2x.png');
				if (this.isOnePlus) {
					this.wcsText = this.sanitizer.bypassSecurityTrustHtml(
						lang.inactiveCallIdOverlayOnePlusDesc.body.replace('{0}', serviceId)
					);
				} else {
					this.wcsText = this.sanitizer.bypassSecurityTrustHtml(
						lang.inactiveCallIdOverlayDesc.body.replace('{0}', serviceId)
					);
				}
				this.fullModal.bodyContentSafeHtml = this.wcsText;
				this.fullModal.primaryButtonText = lang.inactiveCallIdOverlayConfirmBtn.body;
				this.fullModal.primaryButtonClick = this.fullModal.primaryButtonClick = () => {
					this.cross.showNudge = false;
					this.showConfigurationSpinner = true;
					this.productService.BuyOrRemoveBundle(this.productCode, callIdStatus.active).subscribe(
						() => {
							tagging.bill.callOptions.callIDJourney.success.data.journey_subcategory = callOptionAction.activar;
							this.tagging.track(
								tagging.bill.callOptions.callIDJourney.success.eventName,
								tagging.bill.callOptions.callIDJourney.success.data
							);
							this.stauts = callIdStatus.pendingActivation;
							this.stautsText = lang.CallIDPendingActivationStatus.body;
							this.yellow = true;
							this.showToggle = false;
							this.fullModal.hide();
							this.cross.toastMessage = lang.deactivationSucessToast.body;
							this.cross.showToast = true;
						},
						(error) => {
							let err;
							if (typeof error.error === 'string') {
								err = JSON.parse(error.error);
							} else {
								err = error.error;
							}
							this.fullModal.hide();
							if (err.ecode && err.ecode.toString() === BIZTALKERROR) {
								this.appService.NudgeBiztalkError();
								this.tagging.taggingBiztalkNudgeError();
							}
						}
					);
				};
				this.fullModal.secondaryButtonStyle = 'btn-gray';
				this.fullModal.secondaryButtonText = lang.inactiveCallIdOverlayCancelBtn.body;
				this.fullModal.secondaryButtonClick = () => {
					this.fullModal.hide();
				};
			}
			if (this.stauts.toLowerCase() === callIdStatus.active.toLowerCase()) {
				this.tagging.track(
					tagging.bill.callOptions.clickCallIdToggleDesActive.eventName,
					tagging.bill.callOptions.clickCallIdToggleDesActive.data
				);
				tagging.bill.callOptions.callIDJourney.firstStep.data.journey_subcategory = callOptionAction.desactivar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.firstStep.eventName,
					tagging.bill.callOptions.callIDJourney.firstStep.data
				);
				tagging.bill.callOptions.callIDJourney.secondStep.data.journey_subcategory = callOptionAction.desactivar;
				this.tagging.track(
					tagging.bill.callOptions.callIDJourney.secondStep.eventName,
					tagging.bill.callOptions.callIDJourney.secondStep.data
				);
				this.cross.showToast = false;
				this.showConfigurationSpinner = false;
				this.fullModal.headerTitle = lang.activeCallIdOverlayTitle.body;
				this.fullModal.imgSrc = this.appService.getImgFullPath('https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/call-or-minutes-hi@2x.png');
				this.wcsText = this.sanitizer.bypassSecurityTrustHtml(
					lang.activeCallIdOverlayDesc.body.replace('{0}', serviceId)
				);
				this.fullModal.bodyContentSafeHtml = this.wcsText;
				this.fullModal.primaryButtonText = lang.activeCallIdOverlayConfirmBtn.body;
				this.fullModal.primaryButtonClick = this.fullModal.primaryButtonClick = () => {
					this.cross.showNudge = false;
					this.showConfigurationSpinner = true;
					this.productService.BuyOrRemoveBundle(this.productCode, callIdStatus.inactive).subscribe(
						() => {
							this.stauts = callIdStatus.pendingDeactivation;
							this.stautsText = lang.CallIDPendingDeactivationStatus.body;
							this.yellow = true;
							this.showToggle = false;
							this.fullModal.hide();
							tagging.bill.callOptions.callIDJourney.success.data.journey_subcategory = callOptionAction.desactivar;
							this.tagging.track(
								tagging.bill.callOptions.callIDJourney.success.eventName,
								tagging.bill.callOptions.callIDJourney.success.data
							);
							this.cross.toastMessage = lang.deactivationSucessToast.body;
							this.cross.showToast = true;
						},
						(error) => {
							let err;
							if (typeof error.error === 'string') {
								err = JSON.parse(error.error);
							} else {
								err = error.error;
							}
							this.fullModal.hide();
							if (err.ecode && err.ecode.toString() === BIZTALKERROR) {
								this.appService.NudgeBiztalkError();
								this.tagging.taggingBiztalkNudgeError();
							}
						}
					);
				};
				this.fullModal.secondaryButtonStyle = 'btn-gray';
				this.fullModal.secondaryButtonText = lang.activeCallIdOverlayCancelBtn.body;
				this.fullModal.secondaryButtonClick = () => {
					this.fullModal.hide();
				};
			}
		}); // end of translate
	}
}
