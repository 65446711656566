export enum AnalyticsTypeString {
	interaccion = 'interaccion',
	averia = 'averia',
	ok = 'ok',
	mensajeEspera_SCH = '_mensajeEspera_SCH',
	crearaveria = 'crearaveria',
	reboot1 = '_rebootcpe1_SCH',
	reboot2 = '_rebootcpe2_SCH',
	esperareboot = '_mensajeEsperareboot_SCH',
	ticketsrepe = '_Tips_SCH',
	MVL = 'MVL_',
}
