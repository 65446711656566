import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { AppService } from '../../app.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { FaultManagementService } from '../../fault-management/fault-management.service';
import { MyAccountService } from '../../my-account/my-account.service';
import { ProductsAndServicesService } from '../../products-and-services/products-and-services.service';
import { Commons, FIJO, LOCAL_STORAGE_KEYS, prepaidTypes, Validation } from '../../shared/constants/defines';
import { BundleStatus } from '../../shared/enums/bundleStatus.enum';
import { ClientLoginIdType } from '../../shared/enums/clientLoginIdType.enum';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { JourneyCategory, JourneySubCategory } from '../../shared/enums/journey-category.enum';
import { ProductType } from '../../shared/enums/productType.enum';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { SiteType } from '../../shared/enums/siteType.enum';
import { ServiceModel } from '../../shared/models/service.model';
import { CrossVariables, taggingConfig, TaggingViewModel } from '../../shared/models/tagging.model';
import { EncryptService } from '../../shared/services/encrypt.service';
import { ProductConfigurationService } from '../../shared/services/product-configuration.service';
import { ProductService } from '../../shared/services/product.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { TealiumUtagService } from '../../tealium/utag.service';

@Injectable()
export class TaggingHelperService {
	public previous_page: string;
	public error_list = '';
	public isSiebelOrClarify = false;
	public hideBillingOverlay = new Subject<any>();
	public getEncryptedMSISDN = new Subject<string>();
	public exceptionfinishLoadingRoutes = new Array(
		{
			route: config.billing.eBill.route,
			finishLoad: () => {
				return this.storageService.billConfigFinishLoading;
			},
			resetFlag: () => {
				this.storageService.billConfigFinishLoading = false;
			},
		},
		{
			route: config.ProductsAndServices.NewSuperWifi.SuperWifiRegisterEmail.route,
			finishLoad: () => {
				return this.storageService.superWifiRegisterFinishLoading;
			},
			resetFlag: () => {
				this.storageService.superWifiRegisterFinishLoading = false;
			},
		},
		{
			route: config.ProductsAndServices.NewSuperWifi.SuperWifiConfiguration.route,
			finishLoad: () => {
				return this.storageService.superWifiConfigurationFinishLoading;
			},
			resetFlag: () => {
				this.storageService.superWifiConfigurationFinishLoading = false;
			},
		},
		{
			route: config.billing.billingDetails.route,
			finishLoad: () => {
				return this.storageService.billDetailsFinishLoading;
			},
			resetFlag: () => {
				this.storageService.billDetailsFinishLoading = false;
			},
		},
		{
			route: config.FaultManagement.LandingPage.route,
			finishLoad: () => {
				return this.faultManagementService.faultManagementGetTicketsLoading;
			},
			resetFlag: () => {
				this.faultManagementService.faultManagementGetTicketsLoading = false;
			},
		},
		{
			route: config.FaultManagement.OpenNewTicket.route,
			finishLoad: () => {
				return this.faultManagementService.faultManagementOpenNewTicketLoading;
			},
			resetFlag: () => {
				this.faultManagementService.faultManagementOpenNewTicketLoading = false;
			},
		},
		{
			route: config.Inbox.InboxListing.route,
			finishLoad: () => {
				return this.storageService.inboxListingFinishLoad;
			},
			resetFlag: () => {
				this.storageService.billDetailsFinishLoading = false;
			},
		},
		{
			route: config.billing.billConfig.route,
			finishLoad: () => {
				return this.storageService.billConfigAccountFinishLoading;
			},
			resetFlag: () => {
				this.storageService.billConfigAccountFinishLoading = false;
			},
		}
	);
	public exceptionRoutes = new Array(
		{
			route: config.myAccount.permissions.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						if (this.myAccountService.cameFromGDPR) {
							return tagging.permissionsFromGDPR;
						}
						return this.getCurrentPageFromPageConfig(this.router.url);
					}
					if (this.myAccountService.cameFromGDPR) {
						this.view(tagging.permissionsFromGDPR);
					} else {
						this.view(tagging.permissions);
					}
				} else {
					this.populateCrossVariables();
				}
			},
		},
		{
			route: config.ProductsAndServices.editDataSharing.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return this.getCurrentPageFromPageConfig(this.router.url);
					}
					this.view(null, this.storageService.dashboardVariables);
				} else {
					this.populateCrossVariables();
					return this.storageService.dashboardVariables;
				}
			},
		},
		{
			route: config.dashboard.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return this.getCurrentPageFromPageConfig(this.router.url);
					}
					this.view(null, this.storageService.dashboardVariables);
				} else {
					this.populateCrossVariables();
					return this.storageService.dashboardVariables;
				}
			},
		},
		{
			route: config.myAccount.otp.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (this.myAccountService.isEditBankInfo) {
						if (isFaq) {
							return tagging.bankInfoSmsVerfication;
						}
						this.view(tagging.bankInfoSmsVerfication);
					} else {
						if (isFaq) {
							return tagging.editPaymentAddressSmsVerfication;
						}
						this.view(tagging.editPaymentAddressSmsVerfication);
					}
				} else {
					this.populateCrossVariables(tagging.editPaymentAddressSmsVerfication);
				}
			},
		},
		{
			route: config.billing.creditNotes.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (this.storageService.isCreditNote === true) {
						if (isFaq) {
							return tagging.creditNoteAlarm;
						}
						this.view(tagging.creditNoteAlarm);
					} else {
						if (isFaq) {
							return tagging.creditNote;
						}
						this.view(tagging.creditNote);
					}
				} else {
					this.populateCrossVariables(tagging.creditNote);
					return this.storageService.billingVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.special.route,
			handler: (isTrack?, isFaq?) => {
				if (isTrack) {
					this.populateCrossVariables();
					return this.storageService.productAndServiceVariables;
				} else if (isFaq) {
					return this.getCurrentPageFromPageConfig(this.router.url);
				}
			},
		},
		{
			route: config.ProductsAndServices.channelDetails.route,
			handler: (isTrack?, isFaq?) => {
				tagging.extraChannelDetails.page_name = tagging.staticVariables.extraChannelDetails.pageName.replace(
					'<tv channel>',
					this.productAndService.selectedTvExtra.Name
				);
				tagging.extraChannelDetails.page_screen = tagging.staticVariables.extraChannelDetails.pageScreen.replace(
					'<tv channel>',
					this.productAndService.selectedTvExtra.Name
				);
				tagging.extraChannelDetails.page_subcategory_level_3 = tagging.extraChannelDetails.page_screen;
				if (!isTrack) {
					if (isFaq) {
						return tagging.extraChannelDetails;
					}
					this.view(tagging.extraChannelDetails, this.storageService.productAndServiceVariables);
				} else {
					this.populateCrossVariables(tagging.extraChannelDetails);
					return this.storageService.productAndServiceVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.onlineTvDetails.route,
			handler: (isTrack?, isFaq?) => {
				tagging.onlineTvDetails.page_name = tagging.staticVariables.onlineTvDetailsPageSection.pageName.replace(
					'<bundle>',
					this.productAndService.selectedTvExtra.Name
				);
				tagging.onlineTvDetails.page_screen = tagging.staticVariables.onlineTvDetailsPageSection.pageScreen.replace(
					'<bundle>',
					this.productAndService.selectedTvExtra.Name
				);
				tagging.onlineTvDetails.page_subcategory_level_3 = tagging.onlineTvDetails.page_screen;
				if (!isTrack) {
					if (isFaq) {
						return tagging.onlineTvDetails;
					}
					this.view(tagging.onlineTvDetails, this.storageService.productAndServiceVariables);
				} else {
					this.populateCrossVariables(tagging.onlineTvDetails);
					return this.storageService.productAndServiceVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.enjoyMoreDetails.route,
			handler: (isTrack?, isFaq?) => {
				const productCode = this.productAndService.code;
				const product = this.productService.products.find((item) => {
					return item.Code && item.Code.toLowerCase() === productCode.toLowerCase();
				});
				tagging.enjoyMoreDetails.page_name = tagging.staticVariables.enjoyMoreDetailsPageSection.pageName.replace(
					'<bundle name>',
					product.Name
				);
				tagging.enjoyMoreDetails.page_screen = tagging.staticVariables.enjoyMoreDetailsPageSection.pageScreen.replace(
					'<bundle name>',
					product.Name
				);
				tagging.enjoyMoreDetails.page_subcategory_level_2 = tagging.enjoyMoreDetails.page_screen;
				if (!isTrack) {
					if (isFaq) {
						return tagging.enjoyMoreDetails;
					}
					this.view(tagging.enjoyMoreDetails, this.storageService.productAndServiceVariables);
				} else {
					this.populateCrossVariables(tagging.enjoyMoreDetails);
					return this.storageService.productAndServiceVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.extrasCategoryList.route,
			handler: (isTrack?, isFaq?) => {
				tagging.extrasCategoryList.page_name = tagging.staticVariables.extraListCategoryPageSection.pageName.replace(
					'<category>',
					tagging.staticVariables.categories[
						this.productAndService.extraListCategory.title.toString().toLowerCase().replace(/\s/g, '')
					]
				);
				tagging.extrasCategoryList.page_screen =
					tagging.staticVariables.extraListCategoryPageSection.pageScreen.replace(
						'<category>',
						tagging.staticVariables.categories[
							this.productAndService.extraListCategory.title.toString().toLowerCase().replace(/\s/g, '')
						]
					);
				tagging.extrasCategoryList.page_subcategory_level_2 = tagging.extrasCategoryList.page_screen;
				if (
					this.productAndService.extraListCategory.title.toString().toLowerCase() === prepaidTypes.data.toLowerCase()
				) {
					tagging.extrasCategoryList.journey_name = tagging.extrasCategoryListData.journey_name;
					tagging.extrasCategoryList.journey_options = tagging.extrasCategoryListData.journey_options;
					tagging.extrasCategoryList.journey_type = tagging.extrasCategoryListData.journey_type;
					if (!isTrack && isFaq) {
						tagging.extrasCategoryList['&&events'] = tagging.extrasCategoryListData['&&events'];
					}
				}
				if (!isTrack) {
					if (isFaq) {
						return tagging.extrasCategoryList;
					}
					this.view(tagging.extrasCategoryList, this.storageService.productAndServiceVariables);
				} else {
					this.populateCrossVariables(tagging.extrasCategoryList);
					return this.storageService.productAndServiceVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.extraListDetails.route,
			handler: (isTrack?, isFaq?) => {
				tagging.extrasListDetails.page_name = tagging.staticVariables.extrasListDetailsPageSection.pageName
					.replace(
						'<category>',
						tagging.staticVariables.categories[
							this.productAndService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
						]
					)
					.replace('<data bundle name>', this.productAndService.extraProduct.Name);
				tagging.extrasListDetails.page_screen = tagging.staticVariables.extrasListDetailsPageSection.pageScreen.replace(
					'<data bundle name>',
					this.productAndService.extraProduct.Name
				);
				tagging.extrasListDetails.page_subcategory_level_2 =
					tagging.staticVariables.extrasListDetailsPageSection.subCategoryLevel2.replace(
						'<category>',
						tagging.staticVariables.categories[
							this.productAndService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
						]
					);
				tagging.extrasListDetails.page_subcategory_level_3 = tagging.extrasListDetails.page_screen;
				if (
					this.productAndService.extraListCategory?.title?.toString().toLowerCase() === prepaidTypes.data.toLowerCase()
				) {
					tagging.extrasListDetails.journey_name = tagging.extrasListDetailsData.journey_name;
					tagging.extrasListDetails.journey_options = tagging.extrasListDetailsData.journey_options;
					tagging.extrasListDetails.journey_type = tagging.extrasListDetailsData.journey_type;
				}
				if (!isTrack) {
					if (isFaq) {
						return tagging.extrasListDetails;
					}
					this.view(tagging.extrasListDetails, this.storageService.productAndServiceVariables);
				} else {
					this.populateCrossVariables(tagging.extrasListDetails);
					return this.storageService.productAndServiceVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.changeUsername.route,
			handler: (isTrack?, isFaq?) => {
				if (isTrack) {
					this.populateCrossVariables();
					return this.storageService.productAndServiceVariables;
				} else if (isFaq) {
					return this.getCurrentPageFromPageConfig(this.router.url);
				}
			},
		},
		{
			route: config.ProductsAndServices.details.route,
			handler: (isTrack?, isFaq?) => {
				if (isTrack) {
					this.populateCrossVariables();
					return this.storageService.productAndServiceVariables;
				} else {
					if (
						this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Tv.toLowerCase()
					) {
						if (isFaq) {
							return tagging.tvServiceDetails;
						}
						this.view(tagging.tvServiceDetails, this.storageService.productAndServiceVariables);
					} else {
						if (isFaq) {
							return this.getCurrentPageFromPageConfig(this.router.url);
						}
						this.view(null, this.storageService.productAndServiceVariables);
					}
				}
			},
		},
		{
			route: config.ProductsAndServices.entertainmentDetails.route,
			handler: (isTrack?, isFaq?) => {
				tagging.entertainmentDetails.page_name = tagging.staticVariables.entertainmentDetailsPageSection.pageName
					.replace(
						'<category>',
						tagging.staticVariables.categories[
							this.productAndService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
						]
					)
					.replace('<data bundle name>', this.productAndService.extraProduct.Name);
				tagging.entertainmentDetails.page_screen =
					tagging.staticVariables.entertainmentDetailsPageSection.pageScreen.replace(
						'<data bundle name>',
						this.productAndService.extraProduct.Name
					);
				tagging.entertainmentDetails.page_subcategory_level_2 =
					tagging.staticVariables.entertainmentDetailsPageSection.subCategoryLevel2.replace(
						'<category>',
						tagging.staticVariables.categories[
							this.productAndService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
						]
					);
				tagging.entertainmentDetails.page_subcategory_level_3 = tagging.entertainmentDetails.page_screen;
				if (!isTrack) {
					if (isFaq) {
						return tagging.entertainmentDetails;
					}
					this.view(tagging.entertainmentDetails, this.storageService.productAndServiceVariables);
				} else {
					this.populateCrossVariables(tagging.entertainmentDetails);
					return this.storageService.productAndServiceVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.buyMiniXs.route,
			handler: (isTrack?, isFaq?) => {
				this.translate.get('productsServices.miniXSFree.editPageTitle').subscribe((data) => {
					tagging.promotionDetails.page_name = tagging.staticVariables.promotionDetails.pageName.replace(
						'<promotion name>',
						data
					);
					tagging.promotionDetails.page_screen = tagging.staticVariables.promotionDetails.pageScreen.replace(
						'<promotion name>',
						data
					);
					tagging.promotionDetails.page_subcategory_level_3 =
						tagging.staticVariables.promotionDetails.pageScreen.replace('<promotion name>', data);
				});
				if (isTrack) {
					this.populateCrossVariables(tagging.promotionDetails);
					return this.storageService.productAndServiceVariables;
				} else {
					if (isFaq) {
						return tagging.promotionDetails;
					}
					this.view(tagging.promotionDetails, this.storageService.productAndServiceVariables);
				}
			},
		},
		{
			route: config.ProductsAndServices.landingStatic.route,
			handler: (isTrack?, isFaq?) => {
				this.translate.get('productsServices.miniLine8Euros.editPageTitle').subscribe((data) => {
					tagging.promotionDetails.page_name = tagging.staticVariables.promotionDetails.pageName.replace(
						'<promotion name>',
						data
					);
					tagging.promotionDetails.page_screen = tagging.staticVariables.promotionDetails.pageScreen.replace(
						'<promotion name>',
						data
					);
					tagging.promotionDetails.page_subcategory_level_3 =
						tagging.staticVariables.promotionDetails.pageScreen.replace('<promotion name>', data);
				});
				if (isTrack) {
					this.populateCrossVariables(tagging.promotionDetails);
					return this.storageService.productAndServiceVariables;
				} else {
					if (isFaq) {
						return tagging.promotionDetails;
					}
					this.view(tagging.promotionDetails, this.storageService.productAndServiceVariables);
				}
			},
		},
		{
			route: config.ProductsAndServices.planDetailsStatic.route,
			handler: (isTrack?, isFaq?) => {
				this.translate.get('productsServices.miniLine8Euros.editPageTitle').subscribe((data) => {
					tagging.promotionMoreDetails.page_name = tagging.staticVariables.promotionMoreDetails.pageName.replace(
						'<promotion name>',
						data
					);
					tagging.promotionMoreDetails.page_subcategory_level_3 =
						tagging.staticVariables.promotionMoreDetails.subcategoryLevel3.replace('<promotion name>', data);
				});
				if (isTrack) {
					this.populateCrossVariables(tagging.promotionMoreDetails);
					return this.storageService.productAndServiceVariables;
				} else {
					if (isFaq) {
						return tagging.promotionMoreDetails;
					}
					this.view(tagging.promotionMoreDetails, this.storageService.productAndServiceVariables);
				}
			},
		},
		{
			route: config.myAccount.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return this.getCurrentPageFromPageConfig(this.router.url);
					}
					this.view(null, this.storageService.myaccountVariables);
				} else {
					this.populateCrossVariables();
					return this.storageService.myaccountVariables;
				}
			},
		},
		{
			route: config.ProductsAndServices.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return this.getCurrentPageFromPageConfig(this.router.url);
					}
					this.view(null, this.storageService.productAndServiceVariables);
				} else {
					this.populateCrossVariables();
					return this.storageService.productAndServiceVariables;
				}
			},
		},
		{
			route: config.Inbox.details.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return this.getCurrentPageFromPageConfig(this.router.url);
					}
					this.view(null, this.storageService.messegaesVariables);
				} else {
					this.populateCrossVariables();
					return this.storageService.messegaesVariables;
				}
			},
		},
		{
			route: config.roaming.landing.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return this.getCurrentPageFromPageConfig(this.router.url);
					}
					this.view(null, this.storageService.roamingVariables);
				} else {
					this.populateCrossVariables();
					return this.storageService.roamingVariables;
				}
			},
		},
		{
			route: config.FaultManagement.LandingPage.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return tagging.averiaLandingPage;
					}
					this.view(tagging.averiaLandingPage, this.faultManagementService.specificAveriaVariables);
				} else {
					this.populateCrossVariables();
				}
			},
		},
		{
			route: config.billing.billPaymentDetails.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					if (isFaq) {
						return tagging.billPaymentDetailsLandingPage;
					}
					this.view(
						tagging.billPaymentDetailsLandingPage,
						this.storageService.misPagosErrortaggingObj.error_list ? this.storageService.misPagosErrortaggingObj : null
					);
				} else {
					this.populateCrossVariables();
				}
			},
		},
		{
			route: config.FaultManagement.OpenNewTicket.route,
			handler: (isTrack?, isFaq?) => {
				if (!isTrack) {
					tagging.openNewTicket.page_name = tagging.staticVariables.faultManagement.openTicket.pageName.replace(
						'<temp-name>',
						this.faultManagementService.templateName
					);
					tagging.openNewTicket.page_screen = this.faultManagementService.templateName;
					tagging.openNewTicket.page_subcategory_level_1 = this.faultManagementService.templateName;
					if (isFaq) {
						return tagging.openNewTicket;
					}
					this.view(tagging.openNewTicket);
				} else {
					this.populateCrossVariables();
				}
			},
		}
	);
	/** maps error_list string value to KO journey name
	 * journey names obj keys
	 * error_list values for each journey name
	 */
	error_listMapObject: any = {};
	constructor(
		private subscriptionService: SubscriptionService,
		private storageService: StorageService,
		private tealiumUtagService: TealiumUtagService,
		private router: Router,
		private myAccountService: MyAccountService,
		private productService: ProductService,
		private productAndService: ProductsAndServicesService,
		private configurationService: ConfigurationService,
		private productConfigurationService: ProductConfigurationService,
		private utilities: UtilitiesService,
		private faultManagementService: FaultManagementService,
		private translate: TranslateService,
		private app: AppService,
		private encryptService: EncryptService,
		private location: Location
	) {
		this.subscriptionService.customerSubject.subscribe((service) => {
			this.encryptMsisdn(service.id);
			this.storageService.billingVariables.billing_ebilling = '';
		});
		this.storageService.onPdfDownload.subscribe((documentId) => {
			tagging.crossEvents.downloadDocument.data.document_name = documentId
				? documentId
				: tagging.staticVariables.documentName;
			this.track(tagging.crossEvents.downloadDocument.eventName, tagging.crossEvents.downloadDocument.data);
		});
		this.storageService.onErrorOccurs.subscribe((error) => {
			CrossVariables.error_list = this.error_list ? this.error_list : '';
		});
		this.app.settingsSubject.subscribe(() => {
			// Set products visibility for Adobe target
			this.setProductsConfig();
		});
	}
	public populateCrossVariables(tealiumData?: any) {
		this.resetCrossVariables();
		let currentPage;
		if (tealiumData) {
			currentPage = tealiumData;
		} else {
			const pageRoute: string = this.location.path() || '/';
			if (pageRoute !== '/') {
				// jsonpath refactor: removed JsonQuery.query()
				currentPage = this.getCurrentPageFromPageConfig(pageRoute);
			}
		}

		const customerType: string = !this.storageService.userProfile
			? ClientTypology.notLogin
			: this.getUserType(this.storageService.userProfile.customerType, ClientTypology);

		// Service Level tagging
		CrossVariables.client_id_crm =
			this.subscriptionService.customerData?.currentService?.siteId ||
			this.subscriptionService.customerData?.customerAccountsId ||
			this.storageService.userProfile?.sites?.[0]?.id;

		if (this.subscriptionService.customerData?.services?.length > 0) {
			const servicePackage = this.subscriptionService.customerData.services
				.filter((el) => {
					return el.siteId === this.subscriptionService.customerData.currentService.siteId;
				})
				.find((serv) => {
					return serv.package ? true : false;
				});
			if (servicePackage) {
				if (this.subscriptionService.superOffer === 'ON19' || this.subscriptionService.superOffer === 'Destiny') {
					CrossVariables.client_bundle = 'Destiny' + servicePackage ? servicePackage.package.type : 'no paquete';
				} else {
					CrossVariables.client_bundle = servicePackage ? servicePackage.package.type : 'no paquete';
				}
			}
			if (this.subscriptionService.serviceListSite && this.subscriptionService.serviceListSite.length > 0) {
				const servicesWithCode: ServiceModel[] = this.subscriptionService.serviceListSite.filter((service) => {
					return service.tarrifCode;
				});
				CrossVariables.client_products = _.join(
					_.map(servicesWithCode, function (service: any): string {
						return service.name + ':' + service.tarrifCode;
					}),
					','
				);
			}
			this.setClientVariables();
			CrossVariables.client_id = this.subscriptionService.customerData.currentService
				? this.subscriptionService.customerData.currentService.siteId +
				  '-' +
				  this.subscriptionService.customerData.currentService.id
						.toString()
						.substring(this.subscriptionService.customerData.currentService.id.toString().length - 3)
				: '';
			CrossVariables.client_id_ev = CrossVariables.client_id ? CrossVariables.client_id.replace('-', '') : '';
			this.setPrepaidStatusVariable();
		}
		if (
			this.productService.products &&
			this.productService.products.length > 0 &&
			this.router.url.indexOf(config.ProductsAndServices.route) !== -1
		) {
			const products = this.productService.products.filter(function (item) {
				return (
					item.currentStatus &&
					(item.currentStatus.toLocaleLowerCase() === BundleStatus.Active.toLocaleLowerCase() ||
						item.currentStatus.toLocaleLowerCase() === BundleStatus.PendingDeactivation.toLocaleLowerCase()) &&
					item.ProductType &&
					(item.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() ||
						item.ProductType.toLocaleLowerCase() === ProductType.WorryFree.toLocaleLowerCase() ||
						item.ProductType.toLocaleLowerCase() === ProductType.OnlineTvPackage.toLocaleLowerCase() ||
						item.ProductType.toLocaleLowerCase() === ProductType.Content.toLocaleLowerCase())
				);
			});
			const addons = products.map((item) => {
				return item.Name || item.Code.split('_')[0];
			});
			CrossVariables.service_addons = addons.join(',');
		}
		// DXL to return the user login username type
		if (this.storageService.userProfile) {
			CrossVariables.client_privileges =
				tagging.staticVariables.clientPrivileges[this.storageService.userProfile.profileType.toLowerCase()];
			CrossVariables.client_crm = 'amdocs'; // DXL to return the client Type
			CrossVariables.client_login_id_type = this.checkClinetLoginIdType(this.storageService.userProfile.username);
		}
		CrossVariables.client_login_type = this.storageService.client_login_type;
		CrossVariables.client_typology = customerType;
		if (currentPage) {
			let levelList: string = '';
			CrossVariables.page_platform = 'web';
			CrossVariables.page_section = currentPage.page_section;
			if (currentPage.page_subcategory_level && currentPage.page_subcategory_level.length) {
				currentPage.page_subcategory_level.forEach((elem: string, index: number) => {
					CrossVariables[`page_subcategory_level_${index + 1}`] = elem;
					levelList === '' ? (levelList = elem) : (levelList = levelList.concat(`:${elem}`));
				});
			} else {
				CrossVariables.page_subcategory_level_1 = currentPage.page_subcategory_level_1
					? currentPage.page_subcategory_level_1
					: '';
				CrossVariables.page_subcategory_level_2 = currentPage.page_subcategory_level_2
					? currentPage.page_subcategory_level_2
					: '';
				CrossVariables.page_subcategory_level_3 = currentPage.page_subcategory_level_3
					? currentPage.page_subcategory_level_3
					: '';
				CrossVariables.page_subcategory_level_4 = currentPage.page_subcategory_level_4
					? currentPage.page_subcategory_level_4
					: '';
			}

			if (currentPage.page_name) {
				CrossVariables.page_name = currentPage.page_name;
			} else {
				CrossVariables.page_name = levelList
					? `${currentPage.site_section}:${levelList}`
					: `${currentPage.site_section}`;
			}
			CrossVariables.page_detail = currentPage.page_detail ? currentPage.page_detail : '';
			CrossVariables.site_section = currentPage.site_section ? currentPage.site_section : '';

			// --- New tagging vars ---
			CrossVariables.asset_name = currentPage.asset_name;
			CrossVariables.navigation_level_1 = currentPage.navigation_level_1;
			CrossVariables.navigation_level_2 = currentPage.navigation_level_2;
			CrossVariables.navigation_level_3 = currentPage.navigation_level_3;
			CrossVariables.navigation_level_4 = currentPage.navigation_level_4;
			CrossVariables.navigation_level_5 = currentPage.navigation_level_5;

			CrossVariables.event_category = currentPage.event_category;
			CrossVariables.event_context = currentPage.event_context;
			CrossVariables.event_label = currentPage.event_label;
			CrossVariables.event_action = currentPage.event_action;
			CrossVariables['&&events'] = currentPage['&&events'] ? currentPage['&&events'] : '';
			// --- END: New tagging vars ---

			CrossVariables.page_screen = currentPage.page_screen;
			CrossVariables.page_typology = currentPage.page_typology;
			CrossVariables.error_list = this.error_list ? this.error_list : '';
			// if view -currentPage - has a journy object then add it to CrossVariable
			if (currentPage.journey) {
				(CrossVariables['journey_name'] = currentPage.journey.journey_name),
					(CrossVariables['journey_process'] = currentPage.journey.journey_process),
					(CrossVariables['journey_detail'] = currentPage.journey.journey_detail),
					(CrossVariables['journey_type'] = currentPage.journey.journey_type),
					(CrossVariables['journey_status'] = currentPage.journey.journey_status),
					(CrossVariables['journey_subcategory'] = currentPage.journey.journey_subcategory),
					(CrossVariables['journey_category'] = customerType);
				delete currentPage.journey;
			} else {
				(CrossVariables['journey_name'] = currentPage.journey_name ? currentPage.journey_name : ''),
					(CrossVariables['journey_type'] = currentPage.journey_type ? currentPage.journey_type : ''),
					(CrossVariables['journey_options'] = currentPage.journey_options ? currentPage.journey_options : '');
				if (
					CrossVariables['page_subcategory_level_1'].toLowerCase() ===
					tagging.staticVariables.extraPageSection.data_list.page_subcategory_level_1.toLowerCase()
				) {
					CrossVariables['journey_category'] = this.getUserType(
						this.storageService.userProfile.customerType,
						ClientTypology
					).toLowerCase();
					CrossVariables['journey_subcategory'] =
						this.subscriptionService.customerData.SiteType.toLowerCase() ===
						tagging.staticVariables.extraPageSection.pay.postpaid.toLowerCase()
							? tagging.staticVariables.extraPageSection.pay.pospago
							: tagging.staticVariables.extraPageSection.pay.prepago;
				}
			}
		}
		CrossVariables.service_plan = this.storageService.service_plane;
		this.setPageOriginalRefering();
		CrossVariables.page_url = decodeURIComponent(window.location.href);

		// Will be retrived from DXL in Subscription API
		CrossVariables.serviceAES256 = this.storageService.serviceAES256;
		CrossVariables.msisdn = this.storageService.msisdn;
		CrossVariables.superoffer = this.storageService.superoffer;

		CrossVariables.client_login_status = !this.storageService.userProfile ? 'no logado' : 'logado';
		CrossVariables.marketing_cloud_id = '02404517472424657300292996006776614777';
		// short term solution  2359
		// this.remove_GDPR_PrivateInfoFromTelium(CrossVariables);
		CrossVariables.visitor_login_status = CrossVariables.client_login_status;

		CrossVariables.help_bubble_status = this.setChatBubbleStatus();

		this.dontSendErrorVariables();
	}
	private setClientVariables(): void {
		if (this.subscriptionService.customerData && this.subscriptionService.customerData.currentService) {
			CrossVariables.client_hierarchy = this.subscriptionService.customerData.currentService.siteType;
			// set service Name
			if (this.subscriptionService.customerData.currentService.name) {
				if (
					this.subscriptionService.customerData.currentService.name.toLocaleLowerCase() === FIJO &&
					this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
						ServiceType.VodafoneEnTuCasa.toLocaleLowerCase()
				) {
					CrossVariables.service_type = ServiceType.VodafoneEnTuCasa;
				} else {
					CrossVariables.service_type = this.subscriptionService.customerData.currentService.name;
				}
			}
			CrossVariables.client_debt = this.subscriptionService.customerData.currentService.overdue
				? Commons.yes
				: Commons.no;
			CrossVariables.client_segment = this.subscriptionService.customerData.currentService.segment || '';
			CrossVariables.client_status = this.subscriptionService.customerData.currentService.clientStatus || '';
		}
	}

	setChatBubbleStatus() {
		if (this.configurationService.configuration) {
			return this.configurationService.configuration.chatEnabled
				? tagging.staticVariables.faqAvailability.available
				: tagging.staticVariables.faqAvailability.unavailabe;
		} else {
			return '';
		}
	}
	setPrepaidStatusVariable() {
		if (
			this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Prepaid.toLowerCase() ||
			this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase()
		) {
			CrossVariables.prepaid_status = this.storageService.creditBalanceAmount
				? this.storageService.creditBalanceAmount > 0
					? 'si'
					: 'no'
				: 'no';
		} else {
			CrossVariables.prepaid_status = '';
		}
	}
	getUserType(userType: string, userTypeEnum): string {
		if (userType) {
			switch (userType.toLocaleLowerCase()) {
				case CustomerType.Consumer.toLocaleLowerCase():
					if (this.utilities.isConsumerAndHasMicro()) {
						return userTypeEnum.resegmented;
					} else if (this.subscriptionService.customerData.SiteType) {
						return this.checkYuType(userTypeEnum);
					}
					return userTypeEnum.consumer || '';
				case CustomerType.Employee.toLocaleLowerCase():
					return userTypeEnum.business;
				case CustomerType.Authorized.toLocaleLowerCase():
					return userTypeEnum.authorized;
			}
		}
		return '';
	}

	/**
	 * display differnet values of client typology if 100% yu or not
	 */
	checkYuType(userTypeEnum: any): string {
		switch (this.subscriptionService.customerData.SiteType.toLowerCase()) {
			case SiteType.Postpaid.toLowerCase():
				if (this.app.checkDigitalValueToGetYuUser()) {
					return userTypeEnum.postpaidYuUser;
				} else {
					return userTypeEnum.postpaid;
				}
			case SiteType.Prepaid.toLowerCase():
				if (this.app.checkDigitalValueToGetYuUser()) {
					return userTypeEnum.prepaidYuUser;
				} else {
					return userTypeEnum.prepaid;
				}
		}
	}

	private setPageOriginalRefering() {
		const pageOriginalRefering = this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.TAGGING_PAGE_ORIGINAL_REFERING);
		if (pageOriginalRefering) {
			this.storageService.setStorage(LOCAL_STORAGE_KEYS.TAGGING_PAGE_ORIGINAL_REFERING, pageOriginalRefering);
		}
		CrossVariables.page_original_referring =
			this.storageService.getStorage(LOCAL_STORAGE_KEYS.TAGGING_PAGE_ORIGINAL_REFERING) || 'directo';
	}

	private checkClinetLoginIdType(username: string): string {
		const msisdnRegex = new RegExp(Validation.msisdn);
		const emailRegex = new RegExp(Validation.Email);
		const nieRegex = new RegExp(Validation.NIE);
		const nifRegex = new RegExp(Validation.NIF);
		if (msisdnRegex.test(username)) {
			return ClientLoginIdType.msisdn;
		} else if (emailRegex.test(username)) {
			return ClientLoginIdType.email;
		} else if (nieRegex.test(username)) {
			return ClientLoginIdType.nie;
		} else if (nifRegex.test(username)) {
			return ClientLoginIdType.nif;
		} else {
			return '';
		}
	}
	private resetCrossVariables() {
		CrossVariables.client_bundle = '';
		CrossVariables.client_crm = '';
		CrossVariables.client_debt = '';
		CrossVariables.client_hierarchy = '';
		CrossVariables.client_id = '';
		CrossVariables.client_id_ev = '';
		CrossVariables.client_login_type = '';
		CrossVariables.client_privileges = '';
		CrossVariables.client_products = '';
		CrossVariables.client_status = '';
		CrossVariables.client_typology = '';
		CrossVariables.page_name = '';
		CrossVariables.page_platform = '';
		CrossVariables.page_section = '';
		CrossVariables.site_section = '';
		CrossVariables.page_subcategory_level_1 = '';
		CrossVariables.page_subcategory_level_2 = '';
		CrossVariables.page_subcategory_level_3 = '';
		CrossVariables.page_subcategory_level_4 = '';
		CrossVariables.page_subcategory_level_5 = '';
		CrossVariables.page_subcategory_level_6 = '';
		CrossVariables.page_screen = '';
		CrossVariables.page_typology = '';
		CrossVariables.page_detail = '';
		CrossVariables.page_url = '';
		CrossVariables.page_original_referring = '';
		CrossVariables.service_addons = '';
		CrossVariables.service_plan = '';
		CrossVariables.service_type = '';
		CrossVariables.client_login_id_type = '';
		CrossVariables.client_segment = '';
		CrossVariables.marketing_cloud_id = '';
		CrossVariables.client_login_status = '';
		CrossVariables.client_id_crm = '';
		CrossVariables.serviceAES256 = '';
		CrossVariables.msisdn = '';
		CrossVariables.superoffer = '';
		// --- New tagging vars ---
		CrossVariables.asset_name = '';
		CrossVariables.navigation_level_1 = '';
		CrossVariables.navigation_level_2 = '';
		CrossVariables.navigation_level_3 = '';
		CrossVariables.navigation_level_4 = '';
		CrossVariables.navigation_level_5 = '';
		CrossVariables.event_category = '';
		CrossVariables.event_context = '';
		CrossVariables.event_label = '';
		CrossVariables.error_list = '';
		// --- END: New tagging vars ---
		CrossVariables['journey_name'] = '';
		CrossVariables['journey_process'] = '';
		CrossVariables['journey_detail'] = '';
		CrossVariables['journey_type'] = '';
		CrossVariables['journey_status'] = '';
		CrossVariables['journey_category'] = '';
	}
	public resetCrossVariablesAfterLogout() {
		this.storageService.client_login_type = '';
		this.storageService.serviceAES256 = '';
		this.storageService.service_plane = '';
		this.storageService.billingVariables.billing_ebilling = '';
		this.storageService.creditBalanceAmount = null;
		CrossVariables.prepaid_status = '';
		this.storageService.msisdn = '';
	}
	public sendViewState(routeVariables?: any) {
		const exceptionRoute = _.find(this.exceptionRoutes, (item) => {
			return this.router.url.indexOf(item.route) !== -1;
		});
		if (exceptionRoute) {
			exceptionRoute.handler();
		} else {
			if (routeVariables) {
				this.view(null, routeVariables);
			} else {
				this.view();
			}
		}
	}
	public getExtraCrossVariables() {
		const exceptionRoute = _.find(this.exceptionRoutes, (item) => {
			return this.router.url.indexOf(item.route) !== -1;
		});
		if (exceptionRoute) {
			return exceptionRoute.handler(true);
		} else {
			return null;
		}
	}
	public view(tealiumData?: any, routeVariables?: any) {
		this.viewReplace(true, tealiumData, routeVariables);
	}
	public viewReplace(replace: boolean, tealiumData?: any, routeVariables?: any) {
		// in case of routeVariables is null it will use global CrossVeriable without extra spacial route cross veriable
		taggingConfig.navigationEnd = false;
		taggingConfig.componentFinishInit = false;
		this.resetFinishLoadingFlags();
		setTimeout(() => {
			this.populateCrossVariables(tealiumData);
			if (routeVariables) {
				const stateVariables = _.cloneDeep(CrossVariables);
				stateVariables['visitor_permission_diagnostics'] =
					!this.storageService.userProfile || !this.storageService.checkGdprDiagnosticFlag() ? 'true' : 'false';
				this.tealiumUtagService.view(
					_.mapValues(_.merge(stateVariables, routeVariables), (prop, key) => {
						return this.replaceSpecialCharReplace(prop, replace, key);
					})
				);
			} else {
				CrossVariables['visitor_permission_diagnostics'] =
					!this.storageService.userProfile || !this.storageService.checkGdprDiagnosticFlag() ? 'true' : 'false';
				this.tealiumUtagService.view(
					_.mapValues(CrossVariables, (prop, key) => {
						return this.replaceSpecialCharReplace(prop, replace, key);
					})
				);
			}
			this.resetErrorVariables();
			this.storageService.notRestErrorVariables = false;
			// clear error_list with each track
			this.error_list = '';
		}, 0);
	}

	public track(eventName: string, data: any, pageVariables?: any, specificVariables?: any) {
		this.trackReplace(eventName, data, true, pageVariables, specificVariables);
	}
	public trackReplace(eventName: string, data: any, replace: boolean, pageVariables?: any, specificVariables?: any) {
		let exceptionCrossVariables;
		if ((<any>window).utag_data) {
			(<any>window).utag_data['event_name'] = eventName;
		}
		let finalCrossVariables = null;
		const exceptionRoute = _.find(this.exceptionRoutes, (item) => {
			return this.router.url.indexOf(item.route) !== -1;
		});
		if (data && data.journey) {
			(data.journey_name = data.journey.journey_name),
				(data.journey_process = data.journey.journey_process),
				(data.journey_detail = data.journey.journey_detail),
				(data.journey_type = data.journey.journey_type),
				(data.journey_status = data.journey.journey_status),
				(data.journey_category = data.journey.journey_category),
				delete data.journey;
		}
		if (data && data.event) {
			(data.event_category = data.event.event_category),
				(data.event_context = data.event.event_context),
				(data.event_label = data.event.event_label),
				(data.event_name = data.event.event_name);
			delete data.event;
		}

		exceptionCrossVariables = specificVariables;
		if (exceptionRoute || specificVariables) {
			exceptionCrossVariables = exceptionRoute
				? _.merge(exceptionRoute.handler(true), specificVariables)
				: specificVariables;
			if (pageVariables) {
				this.populateCrossVariables(pageVariables);
			}
			if (this.isSiebelOrClarify) {
				CrossVariables.client_crm = 'spirit';
			}
			finalCrossVariables = _.cloneDeep(CrossVariables);
			if (exceptionCrossVariables) {
				finalCrossVariables = _.merge(finalCrossVariables, exceptionCrossVariables);
			}
		} else {
			this.populateCrossVariables(pageVariables);
			if (this.isSiebelOrClarify) {
				CrossVariables.client_crm = 'spirit';
			}
			finalCrossVariables = _.cloneDeep(CrossVariables);
		}
		delete finalCrossVariables.event_name;
		finalCrossVariables = _.merge(finalCrossVariables, data);
		finalCrossVariables['visitor_permission_diagnostics'] =
			!this.storageService.userProfile || !this.storageService.checkGdprDiagnosticFlag() ? 'true' : 'false';
		this.tealiumUtagService.track(
			eventName,
			_.mapValues(finalCrossVariables, (prop, key) => {
				return this.replaceSpecialCharReplace(prop, replace, key);
			})
		);
		// change this to async with above line
		this.isSiebelOrClarify = false;
		// clear error_list with each track
		this.error_list = '';
	}
	replaceSpecialChar(prop: string, key?) {
		return this.replaceSpecialCharReplace(prop, true, key);
	}
	replaceSpecialCharReplace(prop: string, replace: boolean, key?) {
		let result = '';
		if (prop) {
			if (tagging.replacementExceptionKeys.indexOf(key) === -1 && replace) {
				result = prop
					.toString()
					.toLowerCase()
					.replace(/(<([^>]+)>)/gi, '')
					.normalize('NFD');
				tagging.stringReplacement.forEach((rep) => {
					result = result.replace(rep.regex, rep.replacement);
				});
			} else {
				result = prop;
			}
		}
		return result;
	}
	public encryptMsisdn(msisdn: string) {
		this.encryptApi(msisdn).subscribe((res) => {
			this.getEncryptedMSISDN.next(res);
		});
	}

	encryptApi(msisdn) {
		return this.encryptService.encrypt([msisdn]).pipe(
			map((res: string[]) => {
				if (res.length > 0) {
					const aes = res[0];

					this.storageService.serviceAES256 = aes;
					localStorage.removeItem(LOCAL_STORAGE_KEYS.SERVICEAES256);
					this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.SERVICEAES256, aes);

					this.storageService.msisdn = msisdn;
					localStorage.removeItem(LOCAL_STORAGE_KEYS.MSISDN);
					this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.MSISDN, msisdn);
				}
				return '';
			})
		);
	}
	public setErrorTypeValue(error?: any): string {
		const code = error ? (error.status ? error.status.toString() : '') : '';
		if (code && code.toString().length > 0) {
			switch (code[0]) {
				case '4':
					if (code === '401' || code === '403') {
						return tagging.errorTypes.security;
					} else {
						return tagging.errorTypes.functional;
					}
				default:
					return tagging.errorTypes.tecnical;
			}
		} else {
			return '';
		}
	}
	setErrorVariables(errorObj, description) {
		CrossVariables.error_list = this.error_list ? this.error_list : '';
	}
	checkFinishLoadOfExceptionRouts() {
		const exceptionfinishLoad = _.find(this.exceptionfinishLoadingRoutes, (item) => {
			const url: string = this.router.url.split('?')[0];
			const index: number = url.indexOf(item.route);
			if (index !== -1) {
				return url.substring(index, url.length) === item.route;
			} else {
				return false;
			}
		});
		if (exceptionfinishLoad) {
			return exceptionfinishLoad.finishLoad();
		} else {
			return true;
		}
	}
	resetErrorVariables() {
		if (!this.storageService.notRestErrorVariables) {
			CrossVariables.error_list = '';
		}
	}
	getCurrentPage(url?: string) {
		url = url || this.router.url;
		const expectionalRoute = this.exceptionRoutes.find((pageRoute: any) => {
			return pageRoute.route === url;
		});
		if (expectionalRoute) {
			return expectionalRoute.handler(false, true);
		} else {
			return this.getCurrentPageFromPageConfig(url);
		}
	}

	/**
	 * Retrieves a value or object containing the configuration for the given url
	 *
	 * @param url Current url location (e.g. '/section/subSection')
	 * @param configObj Object with the config to look for (defaults to pages-config.ts)
	 * @returns Value associated to the given url (configObj.section.subSection) or undefined
	 */
	getCurrentPageFromPageConfig<T>(url: string, configObj: object = config): T {
		// jsonpath refactor: updated function to replace JSONPath.prototype.query from jsonpath 1.1.1
		// Split url into property names
		const urlProps: string[] =
			url
				?.split('?')[0]
				.split('/')
				.filter((path) => path) || [];

		// Recursive function to get the value
		const getValue = (props: string[], obj: object) => {
			const prop = props.shift();
			if (obj && typeof obj === 'object' && prop in obj) {
				// If there are props left, call recursively
				if (props.length > 0) {
					return getValue(props, obj[prop]);
				} else {
					// If no props left, return current value
					return obj[prop];
				}
			} else {
				// If the prop does not exist, return undefined
				return undefined;
			}
		};
		return getValue(urlProps, configObj);
	}

	resetBillingVariables() {
		this.storageService.billingVariables.billing_alert = '';
		this.storageService.billingVariables.billing_ebilling = '';
		this.storageService.billingVariables.billing_status = '';
	}
	resetFinishLoadingFlags() {
		this.exceptionfinishLoadingRoutes.forEach((item) => {
			item.resetFlag();
		});
	}
	remove_GDPR_PrivateInfoFromTelium(CrossVariables) {
		CrossVariables.client_id_crm = '';
		CrossVariables.page_original_referring = '';
		CrossVariables.service_msisdn_aes = '';
		CrossVariables.client_id_ev = '';
		CrossVariables.client_id = '';
	}

	taggingBiztalkNudgeError() {
		this.track(
			tagging.crossEvents.biztalkNudge.eventName.replace('{0}', this.getCurrentPage()),
			tagging.crossEvents.biztalkNudge.data
		);
	}

	taggingUnavailabilityOverlay(overlayName: string) {
		const tealiumObject = { ...tagging.unavailabilityOverlay };
		tealiumObject.page_name = tealiumObject.page_name.replace('{0}', overlayName);
		this.view(tealiumObject);
	}

	/**
	 * Method to set the products available or unavailable
	 */
	setProductsConfig(): void {
		if (this.configurationService?.configuration?.productConfiguration) {
			const pConfig = this.configurationService.configuration.productConfiguration
				.filter((productConfig) => {
					return productConfig.adobe;
				})
				.map((productConfig: any) => {
					const showMercury = this.productConfigurationService.shouldShowFeature(productConfig.featureID);
					return `${productConfig.featureID}:${showMercury}`;
				});
			CrossVariables.prod_config = pConfig.join(',');
		}
	}

	/** sets error_list param for ko journies*/
	setKOJourneyErrorListVariable(journeyName: string, errorCode: string = '', errorMessage: string = ''): void {
		if (this.error_listMapObject[journeyName]) {
			this.error_listMapObject[journeyName] =
				this.error_listMapObject[journeyName] +
				',' +
				tagging.staticVariables.error_list.replace('<error code>', errorCode).replace('<error message>', errorMessage);
		} else {
			this.error_listMapObject[journeyName] = tagging.staticVariables.error_list
				.replace('<error code>', errorCode)
				.replace('<error message>', errorMessage);
		}
	}

	/** gets error_list value for ko journies
	 * @param {journeyName} string name of the ko journey
	 */
	getKOJourneyErrorListVariable(journeyName: string): string {
		const errorList: string = this.error_listMapObject[journeyName];
		delete this.error_listMapObject[journeyName];
		return errorList ? errorList : '';
	}

	/** replace characters string
	 * @param {prop} string characteres string
	 */
	public replaceSpecialCharText(prop: string): string {
		return prop
			.toLowerCase()
			.normalize('NFD')
			.replace(/[^(0-9a-zA-Z :\\-_.=+)]/gi, '');
	}
	dontSendErrorVariables(): void {
		delete CrossVariables.error_type;
		delete CrossVariables.error_descripcion;
		delete CrossVariables.error_category;
		delete CrossVariables.error_page;
		delete CrossVariables.error_code;
	}

	fillJourneyCategoryAndSubcategory(taggingData: TaggingViewModel): TaggingViewModel {
		const journeyType: string = this.getUserType(this.storageService.userProfile.customerType, ClientTypology);

		if (journeyType === ClientTypology.postpaid) {
			taggingData.journey_category = JourneyCategory.consumer;
			taggingData.journey_subcategory = JourneySubCategory.postpaid;
		}
		if (journeyType === ClientTypology.prepaid) {
			taggingData.journey_category = JourneyCategory.consumer;
			taggingData.journey_subcategory = JourneySubCategory.prepaid;
		}
		if (journeyType === ClientTypology.consumer) {
			taggingData.journey_category = JourneyCategory.consumer;
			taggingData.journey_subcategory = '';
		}
		if (journeyType === ClientTypology.authorized || journeyType === ClientTypology.business) {
			taggingData.journey_category = JourneyCategory.enterprise;
			taggingData.journey_subcategory = '';
		}

		return taggingData;
	}
}
