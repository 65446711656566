import { Injectable } from '@angular/core';
import { surveyAnswer } from '../constants/defines';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { Equipment } from '../models/equipment.model';
import { TariffPromotionInterface, TariffVoucherResponse } from '../models/tariff-list-response.model';
import { TariffPromotion, Voucher } from '../models/tariff.model';
import { ProductConfigurationService } from './product-configuration.service';

@Injectable({
	providedIn: 'root',
})
export class TariffHelperService {
	constructor(private productConfigurationService: ProductConfigurationService) {}

	public showDelightTvSurvey(vouchers: Voucher[]): boolean {
		if (vouchers?.length) {
			const surveyVoucher: Voucher = vouchers.find(
				(voucher) =>
					(voucher.type === surveyAnswer.negative || voucher.type === surveyAnswer.affirmative) &&
					voucher.status.toLowerCase() === BundleStatus.Active.toLowerCase()
			);
			if (
				!surveyVoucher &&
				this.productConfigurationService.shouldShowFeature(ProductConfigurationID.delightTvSurvey)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public mapPromotions(promotions: TariffPromotionInterface[]): TariffPromotion[] {
		let promos: TariffPromotion[] = [];
		if (promotions.length) {
			promos = promotions.map((promo) => {
				const newPromo: TariffPromotion = new TariffPromotion();
				newPromo.activationDate = promo.activationDate ? new Date(promo.activationDate) : null;
				newPromo.deactivationDate = promo.deactivationDate ? new Date(promo.deactivationDate) : null;
				newPromo.status = promo.status ? promo.status.toUpperCase() : null;
				newPromo.code = promo.code || null;
				newPromo.fee = promo.fee ? parseFloat(promo.fee) : null;
				newPromo.name = promo.name || null;
				return newPromo;
			});
		}
		return promos;
	}

	public mapEquipment(paramEquip: any, item: any): Equipment {
		// jsonpath refactor: added 'any' type due to missing model
		const equipment: Equipment = new Equipment();
		this.mapEquipmentPrices(equipment, paramEquip);
		this.mapEquipmentChangeIDs(equipment, paramEquip);
		equipment.id = item.subscription?.id || null;
		const type: string = paramEquip.type || null;
		equipment.type = type ? type.toUpperCase() : null;
		equipment.hasAppointment = paramEquip.hasAppointment || null;
		return equipment;
	}

	public mapVouchers(paramVouchers: TariffVoucherResponse[]): Voucher[] {
		const vouchers: Voucher[] = paramVouchers.map((vchr) => {
			const voucher: Voucher = new Voucher();
			voucher.type = vchr.type.toUpperCase();
			voucher.status = vchr.status;
			// jsonpath refactor: params read as item['xxx'] are missing in model
			voucher.serviceId = vchr['serviceId'] || null;
			voucher.voucher = vchr.voucher || null;
			voucher.endDate = vchr.endDate || null;
			return voucher;
		});
		return vouchers;
	}

	private mapEquipmentPrices(equipment: Equipment, paramEquip: any): void {
		// jsonpath refactor: added 'any' type due to missing model
		equipment.routerPrice = paramEquip.routerPrice || null;
		equipment.router6Price = paramEquip.router6Price || null;
		equipment.routerDecoPrice = paramEquip.routerDecoPrice || null;
		equipment.routerDeco2Price = paramEquip.routerDeco2Price || null;
		equipment.decoPrice = paramEquip.decoPrice || null;
		equipment.decoAutoPrice = paramEquip.decoAutoPrice || null;
		equipment.deco2Price = paramEquip.deco2Price || null;
		equipment.deco2AutoPrice = paramEquip.deco2AutoPrice || null;
		equipment.decoQuoteAmount = paramEquip.decoQuoteAmount || null;
	}

	private mapEquipmentChangeIDs(equipment: Equipment, paramEquip: any): void {
		// jsonpath refactor: added 'any' type due to missing model
		equipment.changeRouterID = paramEquip.changeRouterID || null;
		equipment.changeRouter6ID = paramEquip.changeRouter6ID || null;
		equipment.changeRouterDecoID = paramEquip.changeRouterDecoID || null;
		equipment.changeDecoID = paramEquip.changeDecoID || null;
		equipment.changeDecoAutoID = paramEquip.changeDecoAutoID || null;
		equipment.changeRouterDeco2ID = paramEquip.changeRouterDeco2ID || null;
		equipment.changeDeco2ID = paramEquip.changeDeco2ID || null;
		equipment.changeDeco2AutoID = paramEquip.changeDeco2AutoID || null;
	}
}
