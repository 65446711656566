<div class="form-group sp-text" [ngClass]="{ 'mva10-style': mva10Textbox }">
	<label *ngIf="label" for="{{ id }}" [ngClass]="{ whiteLabel: upgrade }"> {{ label }} </label>
	<div
		class="warning-caption row"
		*ngIf="(showInlineHint && variable.invalid && !(variable.pristine || parentFormPristine)) || showBackEndError"
	>
		<div class="col-xs-2">
			<div class="icon icon-warning"></div>
		</div>
		<div class="col-xs-10 caption-text">
			<p [innerHTML]="hintText"></p>
		</div>
		<div id="arrow" class="arrow-warning arrow-warning-left"></div>
	</div>
	<ng-content> </ng-content>
	<div
		class="input-txt-container input-txt"
		[ngClass]="{
			focus: showSuccessIcon && (variable.pristine || parentFormPristine),
			success: variable.valid && showSuccessIcon && !(variable.pristine || parentFormPristine) && !showBackEndError,
			fail:
				(showSuccessIcon && variable.invalid && !(variable.pristine || parentFormPristine)) ||
				(showBackEndError && !hideRedBorder)
		}"
	>
		<div
			class="error-message"
			[ngClass]="{ 'error-message-carusal': chaneErrorStyle }"
			*ngIf="
				((patternErrorMessage && !variable.valid && variable.value && enforceValidation) || showError) && !mva10Textbox
			"
		>
			<i class="icon icon-warning"> </i>
			<p>{{ patternErrorMessage }}</p>
		</div>
		<div [ngClass]="{ 'disable-manual-login': dimmedFiled }"></div>
		<div
			class="border"
			*ngIf="mva10Textbox && (focused || (variable.touched && dirtyValidationCheck))"
			[ngClass]="{
				'error-class':
					((!variable.valid || showError) && (variable.value || dirtyValidationCheck) && enforceValidation) ||
					showErrorMessage ||
					showOtherErrorMsg,
				'border-gray': showGrayBorderLabel
			}"
			[style.height]="height + 'px'"
		>
			<div class="placeholder">
				{{ placeholder }}
			</div>
		</div>
		<span
			*ngIf="
				(mva10Textbox &&
					(!variable.valid || showError) &&
					(variable.value || dirtyValidationCheck) &&
					enforceValidation) ||
				showErrorMessage ||
				showOtherErrorMsg
			"
			class="icon icon-warning-notification error-icon"
		>
		</span>
		<img
			[src]="successIconUrl"
			*ngIf="mva10Textbox && showRightIconSuccess && variable.valid && variable.value && !focused && isBulr && showTick"
			class="success-icon"
		/>
		<input
			(blur)="onBlurEventF($event)"
			(click)="showXButtonFn()"
			autocomplete="off"
			[disabled]="disabledField"
			type="{{ type }}"
			class="form-control"
			name=""
			id="{{ id }}"
			[style.height]="
				mva10Textbox && (focused || (variable.touched && dirtyValidationCheck))
					? +height - 11 + 'px !important'
					: height + 'px !important'
			"
			[pattern]="pattern"
			[required]="isRequired"
			[ngClass]="{
				'error': (enforceValidation && !variable.valid) || isMatch,
				'upperCase': isUpperCase,
				'placeholder-right': placeholderToRight,
				'mva10-not-focused-textbox': mva10Textbox && !focused && !(variable.touched && dirtyValidationCheck),
				'mva10-focused-textbox': mva10Textbox && focused
			}"
			[placeholder]="placeholder"
			[maxlength]="max"
			(keypress)="keyPressFn($event)"
			(paste)="onPaste($event)"
			(keyup)="Change($event)"
			(keydown)="onKeyDown($event)"
			(focus)="onFocusEventF()"
			[(ngModel)]="innerValue"
			#variable="ngModel"
			(input)="onInputEventF($event)"
			#setFocusField
		/>
		<span
			*ngIf="
				(mva10Textbox && !variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation) ||
				showErrorMessage
			"
			[ngClass]="{ red: redHelperText }"
			class="error-text"
		>
			{{ patternErrorMessage }}
		</span>
		<span
			*ngIf="mva10Textbox && (showHint || showErrorMessage)"
			[ngClass]="{ 'gray-text': showHint, 'red-text': showErrorMessage }"
		>
			{{ showHint ? hintMessage : ErrorMessage }}
		</span>
		<span *ngIf="mva10Textbox && showHelpMsg" class="gray-text"> {{ helpMessage }} </span>
		<span *ngIf="mva10Textbox && showOtherErrorMsg" class="red-text"> {{ OtherErrorMsg }} </span>
		<a class="icon" id="SpTextComp_a_icon">
			<div class="" *ngIf="showSuccessIcon && variable.valid && !isPassword && !showBackEndError">
				<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/selection.png" alt="" class="selection-icon" />
			</div>
			<div class="" *ngIf="showFailIcon && !variable.valid">
				<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/incorrect.png" alt="" class="selection-icon" />
			</div>
			<span
				class="icon icon-info-circle icon-login"
				id="icon-info-white"
				(click)="openPopup()"
				[ngClass]="{ resizeIcon: !isShow }"
				*ngIf="
					showInfoIcon && !isPassword && (!showSuccessIcon || (showSuccessIcon && !variable.valid) || showBackEndError)
				"
			>
			</span>
			<span
				class="icon icon-hide-password"
				*ngIf="
					showPasswordIcon &&
					isPassword &&
					hashed &&
					!(!variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation)
				"
				[ngClass]="{ resizeIcon: !isShow }"
				(click)="toggleIcon()"
			>
			</span>
			<span
				class="icon icon-show-password"
				*ngIf="
					showPasswordIcon &&
					isPassword &&
					!hashed &&
					!(!variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation)
				"
				[ngClass]="{ resizeIcon: !isShow }"
				(click)="toggleIcon()"
			>
			</span>
		</a>
	</div>
	<button
		id="SpTextComp_btn_reset_value"
		*ngIf="resetButton && showXButton"
		(mousedown)="mouseDownXButton()"
		class="icon-reset"
		type="reset"
	>
		<!-- we call function here also to resolve safari issue untill we found a new solution -->
		<span class="icon icon-close" (click)="clickXButtonInsideTextField()"> </span>
	</button>
	<div class="pop-up-container" *ngIf="isShow">
		<div class="pop-up-background"></div>
		<div id="arrow"></div>
		<div class="pop-up-text">
			<div class="row">
				<div class="col-xs-1">
					<span class="icon icon-info-circle" id="icon-info-white"> </span>
				</div>
				<div class="col-xs-9">
					<p class="txt-rg popuptext" id="myPopup" [innerHTML]="infoText"></p>
				</div>

				<div class="col-xs-1 close-icon">
					<span class="icon icon-close" id="close-info-manual-login" (click)="closePopup()"> </span>
				</div>
			</div>
		</div>
	</div>
</div>
