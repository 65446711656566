import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, UntypedFormControl } from '@angular/forms';
/**
 * custome validateors to call every time changes happens
 * @param pattern {string} value to vaildate formComtrol against this pattern
 * @param required {boolean} value to vaildate formComtrol that it is required
 * returns null if FormControl is valid
 * returns object if FormControl is not valid
 */
export function createTextBoxValidator(pattern: string, required: boolean) {
	const _pattern = new RegExp(pattern);
	return (c: UntypedFormControl) => {
		if (_pattern && required) {
			return c.value && _pattern.test(c.value) ? null : { mismatch: true };
		} else if (_pattern && c.value) {
			return _pattern.test(c.value) ? null : { mismatch: true };
		} else {
			return null;
		}
	};
}
const fn = () => {};

@Component({
	selector: 'sp-text',
	templateUrl: './sp-text.component.html',
	styleUrls: ['./sp-text.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SpTextComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SpTextComponent),
			multi: true,
		},
	],
})
export class SpTextComponent implements OnInit, ControlValueAccessor, OnChanges {
	showXButton = false;
	@Input() height?: string;
	@Input() chaneErrorStyle: boolean;
	@Input() preventSpace: boolean;
	@Output() blurFnEvent = new EventEmitter();
	@Output() focusFnEvent = new EventEmitter();
	@Input() resetButton = false;
	@Input() focused: boolean;
	@Input() mva10Textbox: boolean;
	// boolean to show or hide popup
	@Input()
	isShow = false;
	/**isPassword  if true type will be password otherwise will be text */

	@Input()
	public isPassword: boolean;
	/**this will appear if pattern not equal empty string and the innervalue doesn't match the pattern   */
	@Input()
	patternErrorMessage = '';
	/**this will appear if isRequired not equal false and the innervalue equal empty string   */
	@Input()
	requiredErrorMessage = '';
	/** flag to show or hide password icon */
	@Input()
	showPasswordIcon = false;
	/**flag to show or hide info icon */
	@Input()
	showInfoIcon = false;
	/**flag to show or hide fail icon */
	@Input()
	showFailIcon = false;
	/**flag to hash value in text box if ispassword true or not hashed */
	hashed = false;
	@Input()
	placeholder = '';
	@Input()
	placeholderToRight = false;
	/** The text box label. */
	@Input()
	label = '';
	/**  to show information for the text box  */
	@Input()
	infoText = '';
	/** The flag which indicates if the textbox required or not. */
	@Input()
	isRequired: boolean;
	/** The flag which indicates if the textbox with pattern or not. */
	@Input()
	pattern: string;
	/** The infoIconDetails carries info message*/
	@Input()
	infoIconDetails: string;
	@Input()
	isUpperCase = false;
	/** The inner value which holds the textbox text. */
	innerValue = '';
	/** The inner value which holds the previous textbox text. */
	inputPreviousValue = '';
	/** The inner value which holds the textbox text. */
	@Input()
	isMatch = false;
	/**textbox id */
	@Input()
	id = '';
	@Input()
	textType: string;
	/** to diabled the input text or not */
	@Input()
	disabledField = false;
	@Input()
	upgrade: boolean;
	type: string;
	/**
	 * if forceShowError = true show the erorr outline even if the value is valid
	 */
	@Input()
	showError: boolean;
	/** this output is emitted when click x button inside text field */
	@Output() xButtonClicked = new EventEmitter();
	/** this output used for tagging only */
	@Output() showHidePasswordClicked = new EventEmitter();
	@Output() keyPressEvent = new EventEmitter();
	/** this output used for tagging */
	@Output() showBubbleClicked = new EventEmitter();
	/** this output used for emiting value if bubble closed */
	@Output() hideBubbleClicked = new EventEmitter();

	@Output() contentChange = new EventEmitter();
	@Output() inputEventFn = new EventEmitter();
	@Input() dimmedFiled = false;
	isBulr = false;
	/** input used for showing error red border of text field, error icon , patternErrorMessage*/
	@Input() showErrorMessage = false;
	/** The textTypeStatus whether it is password or normal text. */
	private TextboxType = {
		text: 'text',
		password: 'password',
		number: 'number',
		date: 'date',
	};
	/**the max length of the input text  */
	@Input()
	max: number;
	@Input()
	enforceValidation = false;
	/**boolean to show or not the green and red border and check icon during validation  */
	@Input()
	showSuccessIcon: boolean = false;
	/**boolean to show or not the above hint above the input field*/
	@Input()
	showInlineHint: boolean = false;
	/**hintText to show inside the hint div above the input field used to show errors*/
	@Input()
	parentFormPristine: boolean = false;
	@Input()
	hintText: String = '';
	@Input()
	showBackEndError = false;
	@Input()
	hideRedBorder = false;
	@Input()
	dirtyValidationCheck = false;
	@Input()
	redHelperText = false;
	@Input()
	showRightIconSuccess = true;

	@Input()
	successIconUrl = 'https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/selection.png';
	onlyNumber = false;
	showGrayBorderLabel = false;
	/** Ref to the input textfield using ElemntRef */
	@ViewChild('setFocusField', { static: true }) setFocusField: ElementRef;
	@Input()
	showTick = true;
	/**
	 * if needed to show the placeholder/hint under the text field
	 */
	@Input()
	showHint = false;
	@Input()
	hintMessage: string;

	@Input()
	ErrorMessage: string;
	/** boolean to set as true when mousedown x button inside text field */
	mousedownHappened: boolean = false;
	@Input()
	showHelpMsg = false;
	@Input()
	helpMessage: string;
	@Input()
	showOtherErrorMsg = false;
	@Input()
	OtherErrorMsg: string;
	/** when true sets isBlur to true at ngOnChanges hook */
	@Input()
	setIsBlurTrueAtNgOnChanges: boolean = false;
	/**boolean to diable validation and changing textbox style on foucas out */
	@Input() diableValidationOnfoucsout: boolean = false;
	@ViewChild('variable', { static: true }) inputRef: NgModel;
	/** The call back which will be called if the user focus on the textbox */
	onTouched: () => void = fn;
	/** The call back which will be called if the user change any text on the textbox */
	onChanged: (_: any) => void = fn;
	/**this function used to be eq */
	validateFn: any = () => {};
	/**
	 * refresh the inner value in case of it is not like the old inner value and the new value not equal undefined.
	 * @param  {any} The text value.
	 */
	writeValue(value: any): void {
		if (value !== undefined) {
			this.innerValue = value;
		}
	}
	/**
	 * call the onChanged to refresh the value for the parent component.
	 * @param  {any} The callback value.
	 */
	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}
	/**
	 * call the onTouched to refresh the value for the parent component.
	 * @param  {any} The callback value.
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	/**
	 * called when value changed to validate th value
	 * @param {c} Form Control to be validated
	 */
	validate(c: UntypedFormControl) {
		return createTextBoxValidator(this.pattern, this.isRequired);
	}
	/**
	 * call the blur when component not foucsed
	 */
	onBlur() {
		this.onChanged(this.innerValue);
		this.blurFnEvent.emit();
	}
	/**
	 * call change when value changed
	 */
	Change(event) {
		if (this.max && this.innerValue && this.innerValue.length > +this.max) {
			if (event) {
				event.preventDefault();
			}
			this.innerValue = this.inputPreviousValue;
		}
		if (this.preventSpace) {
			this.innerValue = this.innerValue.split(' ').join('');
		}
		this.onChanged(this.innerValue);
		this.showXButtonFn();
		this.contentChange.emit();
	}
	/**empty constructor */
	constructor(private changeDetector: ChangeDetectorRef) {}
	/**
	 * check if ispassword true if true type will be password otherwise will be text
	 * validateFn function to be equal custom validator createTextBoxValidator
	 */
	ngOnInit() {
		if (this.isPassword) {
			this.type = this.TextboxType.password;
			this.hashed = true;
		} else if (this.textType === 'number') {
			this.type = this.TextboxType.number;
		} else if (this.textType === 'date') {
			this.type = this.TextboxType.date;
		} else {
			this.type = this.TextboxType.text;
		}
		this.validateFn = createTextBoxValidator(this.pattern, this.isRequired);
	}
	/**
	 * call toggleIcon when click on passwodIcon to hashed value or not
	 */
	toggleIcon() {
		this.showHidePasswordClicked.emit();
		if (this.type === this.TextboxType.text) {
			this.type = this.TextboxType.password;
			this.hashed = true;
		} else {
			this.type = this.TextboxType.text;
			this.hashed = false;
		}
	}
	/* function to open the popup  */
	public openPopup() {
		this.showBubbleClicked.emit();
		if (!this.isShow) {
			this.isShow = true;
		}
	}
	/* function to close the popup  */
	public closePopup() {
		this.hideBubbleClicked.emit();
		if (this.isShow) {
			this.isShow = false;
		}
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.patternErrorMessage) {
			this.patternErrorMessage = changes.patternErrorMessage.currentValue;
		}
		if (changes.innerValue) {
			if (changes.innerValue.currentValue.length > this.max) {
				this.innerValue = changes.innerValue.previousValue;
			}
		}
		this.showXButtonFn();
		if (this.setIsBlurTrueAtNgOnChanges) {
			this.isBulr = true;
		}
	}
	onBlurEventF(event) {
		/** check to set gray border only if there is no error */
		if (
			this.inputRef.valid &&
			this.inputRef.value &&
			!(
				(this.inputRef.valid && (this.inputRef.value || this.dirtyValidationCheck) && this.enforceValidation) ||
				this.showErrorMessage
			)
		) {
			this.showGrayBorderLabel = true;
		}
		if (!this.inputRef.value) {
			this.focused = false;
			this.showGrayBorderLabel = false;
		}

		/** apply clickXButtonInsideTextField fun when mousedown on x button inside text field in mobile,tablet,desktop)
		 * we need to stop emitting blur event and keep the text box focus
		 * if we need to prevent blur event on future we can add another condtion here
		 */
		if (this.mousedownHappened) {
			event.preventDefault();
			this.clickXButtonInsideTextField();
			this.mousedownHappened = false;
		} else {
			this.onChanged(this.innerValue);
			this.blurFnEvent.emit();
			this.isBulr = true;
			// to ovveride the pervious condtion which is responsible for chaning textbox border style onblur evenet
			if (this.diableValidationOnfoucsout && this.inputRef.value) {
				this.showGrayBorderLabel = true;
			}
		}
	}
	onFocusEventF() {
		if (this.mva10Textbox) {
			this.focused = true;
			this.showGrayBorderLabel = false;
		}
		this.focusFnEvent.emit();
		this.showXButtonFn();
		this.isBulr = true;
	}
	keyPressFn(e) {
		if (this.preventSpace) {
			const key = e.charCode;
			if (this.isSpace(key)) {
				e.preventDefault();
			}
		}

		this.keyPressEvent.emit();
	}
	onPaste(event) {
		if (this.preventSpace) {
			const newval = event.clipboardData.getData('text/plain').toString();
			event.preventDefault();
			this.innerValue = newval.split(' ').join('');
		}
		this.onChanged(event.clipboardData.getData('text/plain').toString());
		this.inputRef.control.markAsDirty();
		this.changeDetector.detectChanges();
	}
	private isSpace(charCode) {
		return +charCode === 32;
	}
	showXButtonFn() {
		this.showXButton = this.resetButton && this.innerValue !== '';
	}
	onKeyDown(event) {
		const element = event.target as HTMLInputElement;
		if (this.max) {
			if (element.value.length < +this.max || element.value.length === +this.max) {
				this.inputPreviousValue = element.value;
			} else if (event.key !== 'Backspace') {
				event.preventDefault();
			}
		}
		if (this.onlyNumber) {
			if (!this.checkKeyCodeIsNumber(event.keyCode) && event.keyCode !== 8) {
				if (event) {
					event.preventDefault();
				}
				this.innerValue = this.inputPreviousValue;
			} else {
				this.inputPreviousValue = event.target.value;
			}
		}
	}
	checkKeyCodeIsNumber(code) {
		return code >= 48 && code <= 57;
	}

	onInputEventF(event) {
		this.inputEventFn.emit(event);
	}
	/**
	 * Executed when click on x button inside text field.
	 * Keep text field as focused.
	 * Remove value inside text field.
	 * Emit an output xButtonClicked to be used in parent components.
	 */
	clickXButtonInsideTextField() {
		this.showGrayBorderLabel = false;
		this.xButtonClicked.emit();
		this.setFocusField.nativeElement.focus();
		this.innerValue = '';
	}
	/**
	 * Excuted when mousedown on x button inside text field
	 */
	mouseDownXButton() {
		this.mousedownHappened = true;
	}
}
