<div
	[ngClass]="{
		'fade-out-tray': hideContent,
		'backward-transition-tray': trayService?.showBackwardTransition,
		'top-up-rows-anonymous': isAnonymousProcess,
		'change-language': changingLanguage,
		'top-rows-responsive': topupService.useDynamicTray,
		'show-contact-list-icon': topupService.contactListSupported
	}"
	class="top-up-rows"
	tabindex="2"
>
	<div class="languages-container" *ngIf="isAnonymousProcess">
		<span
			class="spanish"
			[ngClass]="topupService.topupLanguage.toString() === 'es' ? 'active-language' : 'inactive-language'"
			(click)="changeLanguage('es')"
		>
			<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/spain@2x.png" alt="" />
			<p>{{ 'common.buttonList.languageEs.text' | translate }}</p>
		</span>
		<span
			class="english"
			[ngClass]="topupService.topupLanguage.toString() === 'en' ? 'active-language' : 'inactive-language'"
			(click)="changeLanguage('en')"
		>
			<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/uk@2x.png" alt="" />
			<p>{{ 'common.buttonList.languageEn.text' | translate }}</p>
		</span>
	</div>
	<div
		class="landing-container"
		[ngClass]="{
			'visibility-hidden': showFullLoader,
			'landing-container-small-margin': isAnonymousProcess,
			'landing-container-anonymous': isAnonymousProcess
		}"
		(scroll)="onScroll($event)"
		#landingContainer
	>
		<div class="service-selector-with-content-container">
			<div class="service-selector-title">
				<p *ngIf="showUImodel">{{ uiModel.rechargeNumber }}</p>
				<sp-mva10-service-selector
					*ngIf="selections"
					[showPlus]="true && !isAnonymousProcess"
					#serviceSelector
					[selections]="selections"
					(selectionChangeEvent)="onServiceChange($event)"
					(plusClicked)="clickPlus()"
					[plusSelected]="topupService.showPlus"
					[selectedItem]="topupService.selectedItemIndex"
				>
				</sp-mva10-service-selector>
			</div>
		</div>
		<form
			[formGroup]="form"
			*ngIf="form && (topupService.showPlus || isAnonymousProcess)"
			[ngClass]="{ 'more-margin': !selections }"
		>
			<sp-text
				id="NewTopUpPrepaidServiceComp_txt_msisdn"
				max="9"
				textType="number"
				[preventSpace]="true"
				(inputEventFn)="onInputFn($event)"
				(focusFnEvent)="onFocus()"
				formControlName="msisdn"
				[pattern]="MsisdnValidation"
				[enforceValidation]="showeMsisdnErrorMessage"
				[patternErrorMessage]="errorMessage"
				placeholder="{{ uiModel.introduceNewMobile }}"
				(blurFnEvent)="onBlur()"
				[mva10Textbox]="true"
				[isRequired]="true"
				required
				tabindex="1"
				[focused]="isFocus"
				[dirtyValidationCheck]="true"
				[showSuccessIcon]="showMsisdnFieldSuccessIcon && topupService.contactListSupported"
			>
			</sp-text>
			<div class="contact-list-icon" *ngIf="topupService.contactListSupported">
				<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/ic-calls-contacts@2x.png" (click)="navigateToContactListPermission()" />
			</div>
		</form>
		<div class="amount-selector-with-content-container" *ngIf="!showFullLoader">
			<div class="swipper">
				<p *ngIf="showUImodel">{{ uiModel.rechargeAmount }}</p>
				<sp-mva10-amount-selector
					*ngIf="showAmountSelector"
					[index]="selectedAmountSelectorValue"
					(slidChangeFn)="selectedAmountSelectorValues($event)"
					[items]="amountSelectorValues"
				>
				</sp-mva10-amount-selector>
			</div>
		</div>
		<div
			class="pega-offer"
			*ngIf="
				showPegaOffer && isValidRechargeAmount && validateOfferDate && !showPegaPlatinumOffer && !topupService.showPlus
			"
		>
			<img
				alt="rewardIcon"
				id="topupLandingPegaExtraBalanceIcon"
				class="pega-offer__reward"
				src="{{ pegaExtraBalanceIcon | imgFullPath }}"
			/>
			<p id="topupLandingPegaExtraBalanceText" class="pega-offer__text" [innerHTML]="pegaOfferText"></p>
		</div>
		<div class="pega-offer" *ngIf="showPegaPlatinumOffer && isValidRechargeAmount && !topupService.showPlus">
			<mva10-c-icon iconId="icon-price-promise" size="24" title="offericon" state="info"> </mva10-c-icon>
			<p
				id="topupLandingPegaPlatinumOfferText"
				*ngIf="showPegaPlatinumOffer"
				class="pega-offer__text"
				[innerHTML]="pegaPlatinumOfferText"
			></p>
			<mva10-c-tooltip [dark]="false" [positionFixed]="'left'">
				<mva10-c-icon
					(click)="sendToolTipPlatinumOfferTagging()"
					triggerTooltip
					iconId="icon-info-circle"
					size="24"
					title="infoicon"
					state="info"
				>
				</mva10-c-icon>
				<p
					id="topupLandingPegaPlatinumToolTip"
					contentTooltip
					[innerHTML]="'v10.prepaidPEGAPlatinumOffer.topupToolTip' | translate"
				></p>
			</mva10-c-tooltip>
		</div>
		<sp-payment-wallet-chevron
			*ngIf="!isAnonymousProcess"
			(chevronDataLoaded)="stopLoading()"
			(chevronClicked)="onChevronClicked()"
			[marginTop]="16"
		>
		</sp-payment-wallet-chevron>
		<div
			class="promo-text-field"
			[ngClass]="{ 'promo-text-field-container': !paymentWalletService.selectedCardText }"
			*ngIf="!isAnonymousProcess && promoCode"
		>
			<mva10-c-text-field [placeholder]="promoCode" [(ngModelInput)]="topupService.promoCode"> </mva10-c-text-field>
		</div>
	</div>
	<div
		class="footer-container"
		[ngClass]="{
			'footer-container-small-margin': topupService.showPlus && selections,
			'footer-shadow': !reachScrollerEnd && pageHasScroller && !isAnonymousProcess,
			'visibility-hidden': showFullLoader,
			'footer-container-anonymous': isAnonymousProcess,
			'multi-lang-small-marign': isAnonymousProcess
		}"
	>
		<div class="button-container-with-payment-buttons">
			<div class="jumbotron-button top-margin">
				<button
					(click)="navigateToIframeEnablement()"
					id="LandingComp_btn_routeToExtrasList"
					type="button"
					class="button red two"
					[disabled]="
						topupService?.newPciSelectedService?.hasPendingPciTransactions ||
						(form?.invalid && (topupService.showPlus || topupService.isAnonymousProcess)) ||
						(isFocus && !showMsisdnFieldSuccessIcon) ||
						isConsultant
					"
					[ngClass]="{
						'btn-disabled':
							topupService?.newPciSelectedService?.hasPendingPciTransactions ||
							(form?.invalid && (topupService.showPlus || topupService.isAnonymousProcess)) ||
							(isFocus && !showMsisdnFieldSuccessIcon) ||
							isConsultant
					}"
				>
					{{ uiModel.continueBtn }}
				</button>
			</div>
			<div class="icons-with-security-text">
				<div class="visa-icon">
					<img [src]="visaImage" alt="" class="img-responsive" />
				</div>
				<div class="master-card-icon">
					<img [src]="masterCardImage" alt="" class="img-responsive" />
				</div>
				<div class="security-text-container-having-text">
					<img src="https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/images/ic-padlock-close.png" *ngIf="breakpoint !== BREAKPOINTS.MOBILE" />
					<p>{{ uiModel.sslSecured }}</p>
				</div>
			</div>
		</div>
	</div>
	<div class="full-loader" *ngIf="showFullLoader">
		<div class="">
			<sp-lottie-loader [lottieImageUrl]="'https://assets-es-sit2.dxlpreprod.local.vodafone.es/assets/loader.json'"> </sp-lottie-loader>
		</div>
	</div>
</div>
