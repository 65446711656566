import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { MyAccountService } from '../../my-account/my-account.service';
import { IconClassNames } from '../../shared/enums/iconClassNames.enum';
import * as constants from '../constants/defines';
import { adaraValues, billQueryString, cachingKeys } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { evict } from '../decorators/evict.decorator';
import { AddressStatus } from '../enums/addressStatus.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { SiteStatus } from '../enums/siteStatus.enum';
import { Address } from '../models/Address.model';
import { PaymentMethodAddress } from '../models/account-edit-payment-method-address.model';
import { BillingAccountSubscription } from '../models/billing-accounts-subscription';
import { BillingAccounts } from '../models/billing-accounts.model';
import { BillingAddress } from '../models/billing-address.model';
import {
	CustomerAccount,
	CustomerAccountNewModel,
	CustomerAccountResponseItem,
	CustomerAccountResponseModel,
	MyAccountAuthorizedResponseModel,
	UpdatedPaymentAddress,
} from '../models/customer-account.model';
import { Debt } from '../models/debt.model';
import { MyAccountAuthorizedInfo } from '../models/myAccountAuthorizedInfo.model';
import { PaymentMethod } from '../models/payment-method.model';
import { PaymentSite } from '../models/payment-site.model';
import { PreferencesAndPermissions } from '../models/preferences-and-permissions.model';
import { UserProfile } from '../models/user-profile.model';
import { UtilitiesService } from '../utils/utilities.service';
import { home5G } from './../constants/defines';
import { ProfileType } from './../enums/profileType.enum';
import { CompanyService } from './company.service';
// MVA10
import { CardImageSelectorModel, IconType, SelectorModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { UtilityService } from '../../core/services/utility.service';
import { CustomerAccountSignStatus } from '../enums/customer-account-sign-status.enum';
import { MyAccountDetailsType } from '../enums/my-account-details-type.enum';
import { ProductManagementEnum } from '../enums/product-management.enum';
import {
	AddressAccountEditDetailsViewModel,
	ContactMedium,
	FullCustomerData,
	MyAccountCredentialData,
	MyAccountCustomerData,
	MyAccountDetailsResponseModel,
	OldAccountDetailsResponseModel,
	UpdateBankAccountInfo,
} from '../models/account-edit-details.model';
import { BankAccountDataResponse } from '../models/bankAccountDataResponse.model';
import { BillTicket } from '../models/bill-details.model';
import { CardSelectorExtendedModel } from '../models/card-selector-extended.model';
import { ServiceModel, TypesCardImageSelectorMode } from '../models/service.model';

@Injectable()
export class CustomerAccountService {
	public resendButton: boolean;
	public otpText: string;
	public otpSettimeoutCalled: boolean;
	public customerAccounts: CustomerAccount[];
	public customerAccountsPendingInstall: CustomerAccount[] = [];
	public customerAccountsPaymentMethods: CustomerAccount[];
	public billingAccounts: BillingAccounts[];
	public customerAccount: CustomerAccount;
	public authorizedInfo: MyAccountAuthorizedInfo;
	public selectedBillingAddress: any;
	public newAddress: any;
	public preferencesAndPermissions: PreferencesAndPermissions;
	/**payment method selected site id */
	public selectedPaymentSite: PaymentSite;
	public selectedBillingAccountId: string;
	public updatedAccountNumber: any;
	public updatedPaymentAddress: UpdatedPaymentAddress;
	public hasOneProfesionalItems: boolean = false;
	isError = false;
	selectedSiteIdFromEBill: string;

	selectDefault: string;
	selectorData: SelectorModel;
	primaryList: Array<CardSelectorExtendedModel>;

	onlineSubscriptionsCard: CardImageSelectorModel;
	oneProfesionalCard: CardImageSelectorModel;
	selectorDataWithSubscriptions: SelectorModel;
	hideSubscriptionsEntryPoint: boolean;

	otherCIFs: String = '';
	selectorCIFData: Array<any>;
	responseHistory: string;
	private productsUpdatedSubject = new Subject();
	loaded: Boolean = false;

	public isSitePrepaid = true;
	// boolean to check if user has bank account or not
	public hasBankAccount: boolean = false;
	// boolean to check if come from pagos screen to my account screen or not
	comeFromPagosToMyAccountScreen: boolean = false;

	public newCustomerAccountData: CustomerAccountNewModel = { contact: {} };

	public hasAdaraSite: boolean;
	public adaraSelectorCard: CardImageSelectorModel;
	public showAdaraSelector: boolean;
	public adaraSelectorData: SelectorModel;
	public adaraSelector: CardImageSelectorModel[] = new Array<CardImageSelectorModel>();
	// Indicates if GetCustomerAccounts with pending install is being called or ont
	isPendingInstallAPICalled: boolean = false;
	// Subject that means the end of the call
	PendingInstallDataLoaded: Subject<CustomerAccount[]> = new Subject<CustomerAccount[]>();

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private subscription: SubscriptionService,
		private myAccountService: MyAccountService,
		private utility: UtilitiesService,
		private utils: UtilityService,
		private appService: AppService,
		private companyService: CompanyService,
		private translateService: TranslateService
	) {
		this.customerAccounts = new Array<CustomerAccount>();
		this.authorizedInfo = new MyAccountAuthorizedInfo();
	}

	get productsUpdated$(): Observable<any> {
		return this.productsUpdatedSubject.asObservable();
	}

	private separatePendingInstallCustomerAccounts(): void {
		this.customerAccountsPendingInstall = [];
		if (this.customerAccounts?.length > 1) {
			this.customerAccounts = this.customerAccounts.reduce((nonPendingCA: CustomerAccount[], item: CustomerAccount) => {
				if (this.isPendingInstallationSiteStatus(item.status)) {
					this.customerAccountsPendingInstall.push(item);
				} else {
					nonPendingCA.push(item);
				}
				return nonPendingCA;
			}, [] as CustomerAccount[]);
		}
		this.customerAccount = this.customerAccount || this.customerAccounts[0];
		this.storageService.customerAccountsLoaded = true;
	}

	/**
	 * Fetch Customer Accounts to get current customer data
	 */
	GetCustomerAccounts(
		holderId?: string,
		debtAmount?: boolean,
		pendingInstallSites?: boolean
	): Observable<CustomerAccountResponseModel> {
		if (!this.customerAccounts) {
			this.customerAccounts = new Array<CustomerAccount>();
		}
		let url: string = API_URLS.CustomerAccount.FetchCustomerAccounts;
		url = this.setCustomerAccountsParameters(url, holderId, debtAmount, pendingInstallSites);
		return this.http.get(url).pipe(
			map((res: CustomerAccountResponseModel) => {
				const items: CustomerAccountResponseItem[] = res.items;
				if (debtAmount) {
					this.customerAccounts = new Array<CustomerAccount>();
					const energySiteIndex: number = items.findIndex((acc) => acc.marketType === 'EN');
					if (energySiteIndex >= 0) {
						items.splice(energySiteIndex, 1);
					}
				}
				items.map((item: CustomerAccountResponseItem) => {
					if (
						!this.customerAccounts.find((site: CustomerAccount) => {
							return site.id === this.mapSiteData(item, holderId).id;
						})
					) {
						this.customerAccounts.push(this.mapSiteData(item, holderId));
					}
				});
				this.separatePendingInstallCustomerAccounts();

				return res;
			})
		);
	}

	private setCustomerAccountsParameters(
		url: string,
		holderId?: string,
		debtAmount?: boolean,
		pendingInstallSites?: boolean
	): string {
		if (holderId) {
			url += `?holderId=${holderId}`;
		}
		if (debtAmount) {
			url += holderId ? '&' : '?';
			url += 'fields=debtAmount';
		}
		if (pendingInstallSites) {
			url += `${url.indexOf('?') > -1 ? '&' : '?'}pendingInstall=true`;
		}
		return url;
	}

	/**
	 * Fetch Customer Accounts to get current customer data by id
	 */
	GetCustomerAccountById(id: string, holderId?: string): Observable<CustomerAccount> {
		let url: string = API_URLS.CustomerAccount.FetchCustomerAccountById.replace('{id}', id);
		if (holderId) {
			url += `&holder-id=${holderId}`;
		}
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);

		return this.http.get(url, { headers }).pipe(
			map((res: OldAccountDetailsResponseModel) => {
				this.customerAccount = this.mapSiteData(res, holderId);
				if (!this.customerAccount.status) {
					this.customerAccount.status = [...this.customerAccounts, ...this.customerAccountsPendingInstall].find(
						(item) => item.id === id
					)?.status;
				}
				this.myAccountService.loadheader(this.customerAccount);
				return this.customerAccount;
			})
		);
	}
	getCustomerPartiesById(id: string) {
		const url = API_URLS.CustomerAccount.FetchCustomerPartiesById.replace('{id}', id);
		let headers = new HttpHeaders();
		headers = headers.append('accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: MyAccountAuthorizedResponseModel) => {
				if (res.company) {
					this.authorizedInfo.companyId = res.company.id || null;
					this.authorizedInfo.companyName = res.company.name || null;
				}
				if (res.contactPoints?.[0]) {
					this.authorizedInfo.email = res.contactPoints[0].email?.fullAddress || null;
					this.authorizedInfo.phone = res.contactPoints[0].fixedLine?.phoneNumber || null;
					this.authorizedInfo.vodafonePhone = res.contactPoints[0].mobile?.msisdn || null;
				}
				this.authorizedInfo.permissions = [];
				if (res.extension?.es) {
					this.authorizedInfo.permissions = res.extension.es.permissions || null;
					this.authorizedInfo.documentId = res.extension.es.document?.id || null;
				}
				if (res.contactPreferences) {
					this.authorizedInfo.contactPoint = res.contactPreferences.contactPoint || null;
					this.authorizedInfo.notificationChannel = res.contactPreferences.notificationChannel || null;
					this.authorizedInfo.notificationTime = res.contactPreferences.notificationTime || null;
				}
				this.myAccountService.loadheader(this.authorizedInfo);
				return this.authorizedInfo;
			})
		);
	}

	getCustomerPartiesByIdFull(id: string): Observable<MyAccountAuthorizedResponseModel> {
		const url: string = API_URLS.CustomerAccount.FetchCustomerPartiesById.replace('{id}', id);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);

		return this.http.get(url, { headers }).pipe(
			map((res: MyAccountAuthorizedResponseModel) => {
				const parsedRes: MyAccountDetailsResponseModel = {
					customer: {
						account: [{ id: res.company?.id }],
						contactMedium: [
							{
								type: MyAccountDetailsType.Email,
								characteristic: { emailAddress: res.contactPoints[0]?.email?.fullAddress },
							},
							{
								type: MyAccountDetailsType.Mobile,
								characteristic: { phoneNumber: res.contactPoints[0]?.mobile?.msisdn },
							},
							{
								type: MyAccountDetailsType.Landline,
								characteristic: { phoneNumber: res.contactPoints[0]?.fixedLine?.phoneNumber },
							},
						],
						id: res.extension?.es?.document?.id,
						name: `${res.infoContact?.name}:${res.infoContact?.lastName}:${res.infoContact?.secondLastName}`,
					},
				};
				this.populateSingletonWithNewModel(parsedRes);
				return res;
			})
		);
	}

	@evict(cachingKeys.FetchCustomerAccountById)
	updateCustomerAccount(userDetails) {
		const url = API_URLS.CustomerAccount.PatchCustomerAccount.replace(
			'{id}',
			this.subscription.customerData.customerAccountsId
		);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('Accept', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};

		return this.http.patch(url, userDetails, options).pipe(
			map((res: CustomerAccount) => {
				this.customerAccount.msisdn = res.msisdn || null;
				this.customerAccount.type = res.type || null;
				this.customerAccount.firstName = res.firstName || null;
				this.customerAccount.familyName = (res.middleName || '') + ' ' + res.familyName || null;
				this.customerAccount.email = res.email || null;
				this.myAccountService.loadheader(this.customerAccount);
				return res;
			})
		);
	}
	updateCustomerParties(id, userDetails) {
		const url = API_URLS.CustomerAccount.PatchCustomerPartiesById.replace('{id}', id);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('Accept', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};

		return this.http.patch(url, userDetails, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
	addAuthorizedUser(id, userDetails) {
		const url = API_URLS.CustomerAccount.PutCustomerPartiesById.replace('{id}', id);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Accept', 'application/json');
		const options = {
			headers: headers,
		};

		return this.http.put(url, userDetails, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	private mapSiteData(
		// jsonpath refactor: added 'any' type due to missing model (CustomerAccountResponseItem | OldAccountDetailsResponseModel)
		item: any,
		holderId?: string
	): CustomerAccount {
		let customerAccount: CustomerAccount = new CustomerAccount();
		customerAccount.id = item.id;
		customerAccount.msisdn = item.msisdn;
		customerAccount.type = item.type;
		customerAccount.status = item.status;
		customerAccount.segment = item.segment;
		customerAccount.xmasVoucher = item.xmasVoucher;
		customerAccount.debtAmount = item.debtAmount;
		customerAccount.emailStatus = item.emailStatus;
		customerAccount.newEmail = item.newEmail;
		customerAccount.confirmedEmail = item.confirmedEmail;
		if (customerAccount.status) {
			customerAccount.statusSP = this.utility.translateStatusToSpanish(customerAccount.status);
		}
		customerAccount.firstName = item.firstName;
		customerAccount.familyName = item.familyName;
		customerAccount.email = item.email;

		// Use null as default value for empty fields above
		customerAccount = this.utility.parseNullValues(customerAccount, null);

		customerAccount.companyId = holderId || '';
		customerAccount.address = new Address();
		const address: Address = item.address;
		if (address && Object.keys(address)) {
			customerAccount.address.formattedAddress = address.formattedAddress;
			customerAccount.address.buildingNo = address.buildingNumber;
			customerAccount.address.country = address.country;
			customerAccount.address.level = address.level;
			customerAccount.address.postcode = address.postcode;
			customerAccount.address.street = address.street;
			customerAccount.address.town = address.town;
			customerAccount.address.status = address.status;

			// Set default address values to empty string
			customerAccount.address = this.utility.parseNullValues(customerAccount.address, '');
		} else {
			customerAccount.address = null;
		}
		customerAccount.pendingSign = item.pdteFirma === CustomerAccountSignStatus.pending;

		return customerAccount;
	}

	private mapPaymentMethodsSiteData(item: any, holderId?: string) {
		const customerAccountsPaymentMethods = new CustomerAccount();
		customerAccountsPaymentMethods.id = item.id || null;
		customerAccountsPaymentMethods.msisdn = item.msisdn || null;
		customerAccountsPaymentMethods.type = item.type || null;
		customerAccountsPaymentMethods.status = item.status || null;
		customerAccountsPaymentMethods.firstName = item.firstName || null;
		customerAccountsPaymentMethods.familyName = item.familyName || null;
		customerAccountsPaymentMethods.companyId = holderId ? holderId : '';
		customerAccountsPaymentMethods.email = item.email || null;
		customerAccountsPaymentMethods.address = new Address();
		const address = item.address || null;
		customerAccountsPaymentMethods.address.formattedAddress = address.formattedAddress || '';
		return customerAccountsPaymentMethods;
	}
	@evict(cachingKeys.FetchCustomerAccountById)
	patchCredential(credential: MyAccountCredentialData): Observable<any> {
		const url: string = API_URLS.CustomerAccount.patchCredential.replace('{id}', this.storageService.userProfile.id);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('accept', 'application/json');
		return this.http.patch(url, JSON.stringify(credential), { headers }).pipe(
			map((response) => {
				return response;
			})
		);
	}

	updateCustomerAddress(addresData) {
		const url = API_URLS.CustomerAccount.PatchCustomerAccount.replace(
			'{id}',
			this.subscription.customerData.customerAccountsId
		);

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');

		return this.http.patch(url, addresData, { observe: 'response', headers: headers }).pipe(
			map((res: any) => {
				this.myAccountService.loadheader(this.customerAccount);

				this.customerAccount.address.status = AddressStatus.pending;

				return res;
			})
		);
	}
	patchPaymentMethodAddress(
		siteId: string,
		billingAccountId: string,
		newAddress: PaymentMethodAddress
	): Observable<any> {
		const body: Object[] = [
			{
				op: 'replace',
				path: '/billingAddress',
				value: {
					formattedAddress: newAddress.formattedAddress,
					doorNumber: newAddress.doorNumber,
					buildingName: newAddress.buildingName,
					buildingNumber: newAddress.buildingNumber,
					level: newAddress.level,
					street: newAddress.street,
					town: newAddress.town,
					county: newAddress.county,
					postCode: newAddress.postalCode,
					status: newAddress.status,
				},
			},
		];
		const url: string = API_URLS.CustomerAccount.patchPaymentMethodAddress
			.replace('{id}', siteId)
			.replace('{billingAccountId}', billingAccountId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		return this.http.patch(url, body, { observe: 'response', headers: headers }).pipe(
			map((res: any) => {
				return res;
			})
		);
	}
	/**
	 */
	GetPaymentCustomerAccounts(
		hierarchyType: string = billQueryString.bill,
		holderId?: string,
		status?: string
	): Observable<CustomerAccount[]> {
		this.customerAccountsPaymentMethods = new Array<CustomerAccount>();
		let url: string = API_URLS.CustomerAccount.FetchCustomerAccounts;
		if (holderId) {
			url += `?holderId=${holderId}&hierarchyType=${hierarchyType}`;
		} else {
			url += `?hierarchyType=${hierarchyType}`;
		}
		if (status) {
			url += `&status=${status}`;
		}

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		return this.http.get(url, { headers }).pipe(
			map((res: any) => {
				// jsonpath refactor: added 'any' type due to missing model
				const items: any[] = res.items;
				items
					.filter((item) => !this.isPendingInstallationSiteStatus(item.status))
					.map((item) => {
						if (
							!this.customerAccountsPaymentMethods.find(
								(el) => el.id === this.mapPaymentMethodsSiteData(item, holderId).id
							)
						) {
							this.customerAccountsPaymentMethods.push(this.mapPaymentMethodsSiteData(item, holderId));
						}
					});

				return this.customerAccountsPaymentMethods;
			})
		);
	}
	/**map billing accounts data */
	mapBillingAccountsData(item: any) {
		const billingAccount = new BillingAccounts();
		billingAccount.id = item.id || null;
		billingAccount.billingAddress = new BillingAddress();
		const address = item.billingAddress || null;
		if (address) {
			billingAccount.billingAddress.buildingNumber = address.buildingNumber || '';
			billingAccount.billingAddress.buildingName = address.buildingName || '';
			billingAccount.billingAddress.doorNumber = address.doorNumber || '';
			billingAccount.billingAddress.level = address.level || '';
			billingAccount.billingAddress.street = address.street || '';
			billingAccount.billingAddress.town = address.town || '';
			billingAccount.billingAddress.country = address.country || '';
			billingAccount.billingAddress.postcode = address.postcode || '';
		}
		billingAccount.paymentMethod = new PaymentMethod();
		const paymentMethod = item.paymentMethod || null;
		if (paymentMethod) {
			billingAccount.paymentMethod.bankName = paymentMethod.bankName || '';
			billingAccount.paymentMethod.accountNumber = paymentMethod.accountNumber || '';
			billingAccount.paymentMethod.bic = paymentMethod.bic || '';
			billingAccount.paymentMethod.iban = paymentMethod.iban || '';
			billingAccount.paymentMethod.type = paymentMethod.type || '';
		}
		billingAccount.debt = new Debt();
		const debt = item.debt || null;
		if (debt) {
			billingAccount.debt.amount = debt.amount || '';
			billingAccount.debt.dueDate = debt.dueDate || '';
		}
		billingAccount.subscriptions = new Array<BillingAccountSubscription>();
		billingAccount.subscriptions = item.subscriptions || null;
		return billingAccount;
	}

	GetBillingAccounts() {
		const url = API_URLS.CustomerAccount.FetchBillingAccounts.replace('{id}', this.selectedPaymentSite.id);

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};

		this.billingAccounts = new Array<BillingAccounts>();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				// jsonpath refactor: added 'any' type due to missing model
				const items = res.items;
				items.map((item) => {
					this.billingAccounts.push(this.mapBillingAccountsData(item));
				});
				return res;
			})
		);
	}
	postGeneratePIN() {
		const body = {
			part1: 'Este es tu codigo ',
			part2: ' Disfruta el resto del día.',
		};

		const url = API_URLS.CustomerAccount.generatePIN;
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.post(url, body, options).pipe(map((res) => res));
	}

	@evict(cachingKeys.getPaymentMethods, cachingKeys.FetchBillingAccounts)
	patchPaymentMethod(
		value: UpdateBankAccountInfo | UpdatedPaymentAddress,
		path: string,
		operation: string,
		verificationPIN?: string
	): Observable<BankAccountDataResponse> {
		const body: Object = {
			op: operation,
			path: path,
			value: value,
		};
		const url: string = API_URLS.CustomerAccount.patchPaymentMethodAddress
			.replace('{id}', this.selectedPaymentSite.id)
			.replace('{billing-account-id}', this.selectedBillingAccountId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json-patch+json');
		if (verificationPIN) {
			headers = headers.append('verification-code', verificationPIN);
		}
		return this.http.patch(url, body, { headers }).pipe(map((res: BankAccountDataResponse) => res));
	}

	getPermissionsAndPreferences() {
		this.preferencesAndPermissions = new PreferencesAndPermissions();
		const url = API_URLS.CustomerAccount.permissionsAndPreferences.replace(
			'{siteId}',
			this.subscription.customerData.currentService.siteId
		);
		return this.http.get(url).pipe(
			map((res: any) => {
				// jsonpath refactor: added 'any' type due to missing model
				this.preferencesAndPermissions.timeFrame = res.preferences.timeFrame || '';
				this.preferencesAndPermissions.notifications = res.preferences.notifications || [];
				this.preferencesAndPermissions.topics = res.preferences.topics || [];
				this.preferencesAndPermissions.contactTopics = res.preferences.contactTopics || [];
				this.preferencesAndPermissions.permissions = res.permissions || [];
				this.preferencesAndPermissions.gdprDiagnostics = res.gdprDiagnostics || false;
			})
		);
	}
	@evict(cachingKeys.permissionsAndPreferences)
	postPermissionsAndPreferences(prefrencesAndPremissions: any, isGDPR?: boolean): Observable<any> {
		const url: string = API_URLS.CustomerAccount.permissionsAndPreferences.replace(
			'{siteId}',
			isGDPR ? 'all' : this.subscription.customerData.currentService.siteId
		);

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		return this.http.patch(url, prefrencesAndPremissions, { headers }).pipe(
			map((res) => {
				return res;
			})
		);
	}
	enableGDPRPermission() {
		return this.postPermissionsAndPreferences(
			{
				permissions: ['DatosTraficoNavegacion'],
			},
			true
		);
	}
	isP1Sites(userProfile: UserProfile) {
		const p1Sites = [
			SiteStatus.Activo.toLowerCase(),
			SiteStatus.Pend_de_Cambio.toLowerCase(),
			SiteStatus.Pend_de_Desconectar.toLowerCase(),
			SiteStatus.Active_Pending.toLowerCase(),
		];
		const currentSiteStatuses = userProfile.sites.filter((site) => p1Sites.includes(site.status.toLowerCase()));
		return currentSiteStatuses.length > 1;
	}
	isP1SiteStatus(userProfile: UserProfile) {
		const p1Sites = [
			SiteStatus.Activo.toLowerCase(),
			SiteStatus.Pend_de_Cambio.toLowerCase(),
			SiteStatus.Pend_de_Desconectar.toLowerCase(),
			SiteStatus.Active_Pending.toLowerCase(),
		];
		return (
			userProfile && userProfile.sites && userProfile.sites.find((site) => p1Sites.includes(site.status.toLowerCase()))
		);
	}

	getCustomerAccountsWS10(id?: string, pendingInstallSites?: boolean): Observable<CustomerAccount[]> {
		this.customerAccounts = new Array<CustomerAccount>();
		let url: string = !id
			? API_URLS['Dashboard-WS10'].CustomerAccount.FetchCustomerAccounts +
			  (pendingInstallSites ? '?pendingInstall=true' : '')
			: API_URLS['Dashboard-WS10'].CustomerAccount.FetchCustomerAccountById.replace('{id}', id);

		if (this.companyService.selectedCompanyId) {
			url += `${url.indexOf('?') > -1 ? '&' : '?'}holderId=${this.companyService.selectedCompanyId}`;
		}
		return this.http.get(url).pipe(
			map((response: CustomerAccountResponseModel): CustomerAccount[] => {
				this.customerAccounts = new Array<CustomerAccount>();
				const items: CustomerAccountResponseItem[] = response.items;
				if (items) {
					this.customerAccounts = this.utils.mapCustomerAccounts(items);
					this.separatePendingInstallCustomerAccounts();
				}
				if (response.firstName) {
					this.appService.initGretting(response.firstName);
				}
				this.checkForAdaraSites(this.storageService.userProfile.sites);
				this.showAdaraSelector = pendingInstallSites
					? this.checkSitesToShowAdaraSelector(items)
					: this.storageService.userProfile.sites.some((site) => site.marketType === adaraValues.CLIENT_ADARA);
				return this.customerAccounts;
			}),
			catchError((error) => {
				return throwError(error);
			})
		);
	}

	getHistory(): Observable<any> {
		const urlHistory = API_URLS['Dashboard-WS10'].CustomerAccount.History.replace(
			'{siteId}',
			this.subscription.customerData.customerAccountsId
		)
			.replace('{subscription-id}', this.subscription.customerData.currentService.id)
			.replace('{start}', moment(new Date()).subtract(6, 'months').format())
			.replace('{end}', moment(new Date()).format());
		return this.http.get(urlHistory).pipe(
			map((response: any) => {
				this.responseHistory = response.items[0].transactionDate;
			}),
			catchError((error) => {
				this.responseHistory = error.error.ecode;
				return throwError(error);
			})
		);
	}

	getSecondarylist(productId?) {
		this.selectorData = new SelectorModel();
		this.selectorData.cardImageSelectorList = [];
		this.isSitePrepaid = true;
		if (this.subscription.serviceListSite) {
			this.selectorData.cardImageSelectorList = this.subscription.serviceListSite.map((item) => {
				this.checkSitePrepaid(item.type);
				return this.mapServiceSelector(item, item.type, productId);
			});
			this.selectorDataWithSubscriptions = this.getServicesListWithSubscriptions();
		}
	}

	public generateAdaraSelector(): SelectorModel {
		this.adaraSelectorCard = {
			id: ProductManagementEnum.adara,
			icon: '#idea-or-innovation-mid',
			title: this.translateService.instant('v10.dashboard.gestion.list.adara.desc'),
			image: this.appService.getImgFullPath(this.translateService.instant('v10.dashboard.gestion.list.adara.image')),
			borderColor: 'none',
			description: null,
			checked: false,
		};
		return {
			cardImageSelectorList: [this.adaraSelectorCard],
		};
	}

	getServicesListWithSubscriptions(): SelectorModel {
		if (!this.onlineSubscriptionsCard) {
			this.hideSubscriptionsEntryPoint = [true, 'true'].includes(
				this.translateService.instant('v10.dashboard.gestion.list.online_subscriptions.hideEP')
			);
			this.onlineSubscriptionsCard = {
				id: ProductManagementEnum.online_subscriptions,
				icon: '#icon-offers-mid',
				title: this.translateService.instant('v10.dashboard.gestion.list.online_subscriptions.desc'),
				image: this.appService.getImgFullPath(
					this.translateService.instant('v10.dashboard.images.product_selector.onlineSubscriptions_light')
				),
				backgroundColor: 'white',
				borderColor: '#999',
				description: null,
				checked: false,
			};
		}

		this.hasOneProfesionalItems = !!this.appService.listSubcriptions?.hasOneProfesionalItems;
		let dataWidthOneProfesionalItems: CardImageSelectorModel[];

		if (this.hasOneProfesionalItems) {
			this.translateService.get('v10.productsServices.businessProducts').subscribe((text) => {
				this.oneProfesionalCard = {
					id: text.id,
					icon: IconClassNames.ICON_SME_MID,
					title: text.titleText,
					image: this.appService.getImgFullPath(
						this.translateService.instant('v10.dashboard.images.product_selector.businessProducts')
					),
					description: text.subtitleText,
					checked: false,
				};
				dataWidthOneProfesionalItems = this.selectorData?.cardImageSelectorList;
				dataWidthOneProfesionalItems.unshift(this.oneProfesionalCard);
			});
		}

		const originalData: CardImageSelectorModel[] = this.hasOneProfesionalItems
			? dataWidthOneProfesionalItems
			: this.selectorData?.cardImageSelectorList || [];

		return {
			cardImageSelectorList: this.hideSubscriptionsEntryPoint
				? originalData
				: [...originalData, this.onlineSubscriptionsCard],
		};
	}

	checkSitePrepaid(type) {
		this.isSitePrepaid = type.toUpperCase() === ServiceType.Prepaid.toUpperCase() ? this.isSitePrepaid : false;
	}

	initCIFSelector() {
		this.loaded = false;
		this.selectorCIFData = this.storageService.userProfile.companies.map((item) => {
			return item.id;
		});

		if (this.storageService.userProfile.hasExternalCompanies) {
			this.translateService.get('common').subscribe((text) => {
				this.otherCIFs = text.itemsList.otherCIFs.body;
				this.selectorCIFData.push(this.otherCIFs);
			});
		}

		this.loaded = true;
	}

	initSelector(productId?: string, includePendingSites?: boolean): void {
		this.selectorCIFData = [];
		this.primaryList = [];
		this.selectorData = new SelectorModel();
		this.selectorData.cardImageSelectorList = [];
		if (this.storageService.userProfile.companies) {
			this.initCIFSelector();
		}

		const sitesToInclude: CustomerAccount[] = includePendingSites
			? [...this.customerAccounts, ...this.customerAccountsPendingInstall]
			: this.customerAccounts;
		if (
			((sitesToInclude && sitesToInclude.length > 1) ||
				this.storageService.userProfile.customerType === ProfileType.AUTHORIZED) &&
			this.storageService.userProfile.customerType !== ProfileType.EMPLOYEE &&
			this.storageService.userProfile.customerType !== ProfileType.SME
		) {
			this.primaryList = sitesToInclude
				.filter((item) => item.marketType !== adaraValues.CLIENT_ADARA)
				.map((item) => {
					const itemCard: CardSelectorExtendedModel = new CardSelectorExtendedModel();
					itemCard.id = item.id;
					itemCard.text = item.address?.formattedAddress?.toString();
					itemCard.checked = false;
					itemCard.value = item.id;
					itemCard.status = item.status;
					itemCard.blueLabel = this.isPendingInstallationSiteStatus(item.status);

					if (item.id === this.subscription.customerData.customerAccountsId) {
						itemCard.checked = true;
						this.selectDefault = itemCard.value;
					}
					return itemCard;
				});
		}
		this.getSecondarylist(productId);
		this.productsUpdatedSubject.next(this.selectorData);
	}

	isPendingInstallationSiteStatus(siteStatus?: string, siteId?: string): boolean {
		const customerAccountsToFilter: CustomerAccount[] =
			this.customerAccounts?.length === 1 && this.customerAccountsPendingInstall?.length === 0
				? this.customerAccounts
				: this.customerAccountsPendingInstall;
		const pendingStatuses: string[] = [
			SiteStatus.Pend_de_Instalar,
			SiteStatus.Pend_de_Instalar_Pend,
			SiteStatus.Pending_installation,
			SiteStatus.Install_Pend,
		];
		siteStatus =
			siteStatus ||
			customerAccountsToFilter
				?.filter((item) => item.marketType !== adaraValues.CLIENT_ADARA)
				.find((site) => site.id === (siteId || this.selectDefault))?.status ||
			customerAccountsToFilter.filter((item) => item.marketType !== adaraValues.CLIENT_ADARA)[0]?.status;
		return pendingStatuses.includes(siteStatus);
	}

	mapServiceSelector(item: ServiceModel, type: string, defaultProductId?: string): CardImageSelectorModel {
		let itemImageCard: CardImageSelectorModel = new CardImageSelectorModel();
		this.translateService.get('v10').subscribe((text) => {
			itemImageCard.title = this.setItemImageCardTitle(item);
			const isHome5G: boolean = item.tarrifCode === home5G.tariffCode;
			const types: TypesCardImageSelectorMode = {
				MOBILEPOSTPAID: this.mobile(item, text.dashboard.images.product_selector.mobile),
				MOBILEPREPAID: this.mobile(item, text.dashboard.images.product_selector.mobile),
				MBBPOSTPAID: this.mbb(
					item,
					isHome5G,
					isHome5G ? text.dashboard.images.product_selector.hogar5g : text.dashboard.images.product_selector.mbb
				),
				MBBPREPAID: this.mbb(item, isHome5G, text.dashboard.images.product_selector.mbb),
				MBBHOLIDAY: this.mbb(item, isHome5G, text.dashboard.images.product_selector.mbb),
				LANDLINE: this.landline(item, text.dashboard.images.product_selector.landline),
				VODAFONEENTUCASA: this.landline(item, text.dashboard.images.product_selector.landline),
				TV: this.tv(item, text.dashboard.images.product_selector.tv),
				TVONLINE: this.tv(item, text.dashboard.images.product_selector.tv),
				ADSL: this.adsl(item, text.dashboard.images.product_selector.fibre_adsl),
				FIBRE: this.adsl(item, text.dashboard.images.product_selector.fibre_adsl),
			};
			itemImageCard = types[type.replace(/ /g, '').toUpperCase()];

			const checkedProduct: string = defaultProductId || this.subscription.customerData.currentService.id;
			itemImageCard = this.setItemImageCardChecked(itemImageCard, item, checkedProduct);
		});
		return itemImageCard;
	}

	public mobile(item: ServiceModel, image: string): CardImageSelectorModel {
		return {
			title: this.setItemImageCardTitle(item),
			description: item.id,
			image: this.appService.getImgFullPath(image),
			icon: IconType.ICON_SIM_MID,
			id: item.id,
		};
	}

	public mbb(item: ServiceModel, isHome5G: boolean, image: string): CardImageSelectorModel {
		return {
			title: this.setItemImageCardTitle(item),
			description: isHome5G ? '' : item.id,
			image: this.appService.getImgFullPath(image),
			icon: isHome5G ? IconType.ICON_ROUTER_MID : IconType.ICON_USB_MODEM_MID,
			id: item.id,
		};
	}

	public landline(item: ServiceModel, image: string): CardImageSelectorModel {
		return {
			title: this.setItemImageCardTitle(item),
			description: item.id,
			image: this.appService.getImgFullPath(image),
			icon: IconType.ICON_FIXED_LINE_MID,
			id: item.id,
		};
	}

	public tv(item: ServiceModel, image: string): CardImageSelectorModel {
		return {
			title: this.setItemImageCardTitle(item),
			image: this.appService.getImgFullPath(image),
			icon: IconType.ICON_TV_MID,
			id: item.id,
		};
	}

	public adsl(item: ServiceModel, image: string): CardImageSelectorModel {
		return {
			title: this.setItemImageCardTitle(item),
			image: this.appService.getImgFullPath(image),
			icon: IconType.ICON_ROUTER_MID,
			id: item.id,
		};
	}

	public setItemImageCardTitle(item: ServiceModel): string {
		return item.name ?? '';
	}

	public setItemImageCardChecked(
		itemImageCard: CardImageSelectorModel,
		item: ServiceModel,
		checkedProduct: string
	): CardImageSelectorModel {
		itemImageCard.checked = false;
		if (item.id === checkedProduct) {
			itemImageCard.checked = true;
		}
		return itemImageCard;
	}

	getMyData(id: string, forceUpdate?: boolean): Observable<CustomerAccountNewModel> {
		if (!forceUpdate && this.newCustomerAccountData && this.newCustomerAccountData.accountId === id) {
			return of(this.newCustomerAccountData);
		}

		let url: string = API_URLS.CustomerAccount.getMyData;

		if (id) {
			url += `?account.id=${id}`;
		}

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');
		const options: Object = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: MyAccountDetailsResponseModel) => {
				return this.populateSingletonWithNewModel(res);
			})
		);
	}

	modifyMyAccountData(
		modifiedData: MyAccountCustomerData,
		siteId: string = this.subscription.customerData.currentService.siteId
	): Observable<CustomerAccountNewModel> {
		const url: string = API_URLS.CustomerAccount.patchMyData.replace('{accountId}', siteId);
		const rawBody: FullCustomerData = {
			...modifiedData,
			account: [{ href: '/customerManagement/v3/customer/' + siteId, id: siteId }],
		};

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');

		return this.http.patch(url, rawBody, { headers }).pipe(
			map((res: MyAccountDetailsResponseModel) => {
				return this.populateSingletonWithNewModel(res);
			})
		);
	}

	populateSingletonWithNewModel(newData: MyAccountDetailsResponseModel): CustomerAccountNewModel {
		this.newCustomerAccountData = {
			...(this.newCustomerAccountData || {}),
			...newData.customer.contactMedium.reduce(
				(result: CustomerAccountNewModel, current: ContactMedium) => {
					if (current.type === MyAccountDetailsType.PostalAddress) {
						const instAdd: AddressAccountEditDetailsViewModel = current.characteristic;
						result.contact[current.type.toLowerCase()] = {
							...instAdd,
							formattedAddress: this.getFormattedAddress(instAdd),
						};
					} else if (current.type) {
						result.contact[current.type.toLowerCase()] =
							current.characteristic.phoneNumber || current.characteristic.emailAddress;
					} else {
						result.contact.favouritePhone = current.characteristic.phoneNumber;
					}
					return result;
				},
				{ contact: {} } as CustomerAccountNewModel
			),
		};

		if (newData.billingAccount?.contact?.length) {
			const billAdd: AddressAccountEditDetailsViewModel =
				newData.billingAccount.contact[0].contactMedium[0].characteristic;
			this.newCustomerAccountData.billingAddress = {
				...billAdd,
				formattedAddress: this.getFormattedAddress(billAdd),
			};
			this.newCustomerAccountData.billingAccountId = newData.billingAccount.id;
		}

		this.newCustomerAccountData.nif = newData.customer.id;
		this.newCustomerAccountData.accountId = newData.customer.account[0].id;
		this.newCustomerAccountData.billingAccountId =
			this.newCustomerAccountData.billingAccountId || this.newCustomerAccountData.accountId;
		this.newCustomerAccountData.msisdn = this.newCustomerAccountData.contact.mobile;

		const splittedName: string[] = newData.customer.name.split(':');
		this.newCustomerAccountData.name = splittedName[0] || '';
		this.newCustomerAccountData.firstSurname = splittedName[1] || '';
		this.newCustomerAccountData.secondSurname = splittedName[2] || '';
		this.newCustomerAccountData.fullName =
			`${this.newCustomerAccountData.name} ${this.newCustomerAccountData.firstSurname} ${this.newCustomerAccountData.secondSurname}`.trim();

		this.newCustomerAccountData = this.utility.parseNullValues(this.newCustomerAccountData);
		return this.newCustomerAccountData;
	}

	getFormattedAddress(address: AddressAccountEditDetailsViewModel): string {
		return (
			(address.street1 ? address.street1 + ', ' : '') +
			(address.street2 ? address.street2 + ', ' : '') +
			(address.postCode ? address.postCode + ' ' : '') +
			(address.city ? address.city + ', ' : '') +
			(address.stateOrProvince || '')
		).trim();
	}

	public checkForAdaraSites(items: CustomerAccount[]): boolean | SelectorModel {
		return items.some((item) => item.marketType === adaraValues.CLIENT_ADARA)
			? ((this.hasAdaraSite = true), (this.adaraSelectorData = this.generateAdaraSelector()))
			: ((this.hasAdaraSite = true), (this.adaraSelectorData = this.generateAdaraSelector()));
	}
	public checkSitesToShowAdaraSelector(sites: CustomerAccountResponseItem[]): boolean {
		const telcoSites: CustomerAccountResponseItem[] = sites.filter(
			(site) => site.clientType !== adaraValues.CLIENT_ADARA || site.marketType !== adaraValues.CLIENT_ADARA
		);
		const adaraSites: CustomerAccountResponseItem[] = sites.filter(
			(site) => site.clientType === adaraValues.CLIENT_ADARA || site.marketType === adaraValues.CLIENT_ADARA
		);
		return telcoSites.length >= 1 && adaraSites.length >= 1;
	}

	public getTicketTraslado(
		relatedPartyId: string,
		ticketType: string,
		ticketDescription: string
	): Observable<BillTicket[]> {
		const url: string = API_URLS.Ticket.getTicketTraslado
			.replace('{relatedPartyId}', relatedPartyId)
			.replace('{ticketType}', ticketType)
			.replace('{ticketDescription}', ticketDescription);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: Record<string, HttpHeaders> = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: BillTicket[]) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getUserSiteIds(): string[] {
		const siteIds: string[] = [];
		this.customerAccounts.forEach((account) => {
			siteIds.push(account.id);
		});
		return siteIds;
	}
}
