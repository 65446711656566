export enum TemplateStatus {
	ok = 'Ok',
	ko = 'Ko',
	finished = 'Finished',
	start = 'start',
	continue = 'continue',
}

export enum TemplateFormType {
	email = 'email',
	cellphone = 'cellphone',
	phone = 'phone',
	celldate = 'celldate',
}

export enum ValidatorType {
	numDir = 'numDir',
	postalCod = 'codPostal',
	avenue = 'calleAvenida',
}

export enum TemplateFormDeeplink {
	chatCasesIot = 'chatCasesIot',
}
