import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { loginErrors } from '../../config/login-errors-config';
import { config } from '../../config/pages-config';
import { AppService } from '../app.service';
import { ErrorHadlingUsingNotificationService } from '../core/services/error-hadling-using-notification.service';
import { HttpRequestMethods, logInQueryErrorMessage, logInQueryErrorType, PAGES } from '../shared/constants/defines';
import { UserSettingToken } from '../shared/enums/user-setting-token.enum';
import { Error } from '../shared/models/error.model';
import { DeepLinkingService } from '../shared/services/deep-linking.service';
import { SpMva10OverlayService } from '../shared/services/sp-mva10-overlay.service';
import { UserSettingsService } from '../shared/services/user-settings.service';
import * as pagesConfig from './../../config/pages-config';

@Injectable()
export class LoginService {
	seibelToken: string;
	seibelDeepLink: string;
	buttonHandlerTypeD: () => void;
	constructor(
		private userSettingsService: UserSettingsService,
		private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService,
		private translate: TranslateService,
		private deviceService: DeviceDetectorService,
		public spMva10OverlayService: SpMva10OverlayService,
		private router: Router,
		private appService: AppService,
		private deepLinkService: DeepLinkingService
	) {}
	siebleRedirection(siteId?, serviceId?) {
		this.userSettingsService.getUserSettingsToken(UserSettingToken.WebToken, siteId, serviceId).subscribe(
			(_) => {
				this.seibelToken = this.userSettingsService.webToken;
				this.router.navigate([config.login.loginSeibelRedirection.route]);
			},
			(error) => {
				this.appService.showFullAppLoader = false;
				const notificationModal = this.errorHadlingUsingNotificationService.errorHandlingUsingNotification(
					error,
					HttpRequestMethods.post,
					PAGES.COMMON,
					true
				);
				notificationModal.show();
			}
		);
	}
	getLoginErrorHandlingType(error) {
		if (!error.error || !error.error.ecode) {
			return '';
		}
		const errorType: string = loginErrors[error.status]?.[error.error.ecode];
		return errorType || '';
	}
	handlerTypeD(errorFromWcs: Error, overlayTrayIndex?: number): void {
		let descriptionText: string = errorFromWcs.descriptionRichText;
		let buttonText: string = this.translate.instant('login.buttonList.overlayCatDBtn.text');
		this.buttonHandlerTypeD = null;
		if (errorFromWcs.errorCode) {
			/**
			 * For now it contains AN ARRAY WITH THE FOLLOWING ERRORS:
			 * ERRORCODES.NOMEERRORCODE (1201),
			 * ERRORCODES.CORPORATENOTALLOWEDMCARE (1020),
			 * ERRORCODES.P3ERRORCODE (1315),
			 * ERRORCODES.CIFP3 (1316)
			 */
			const errorCodesFromWCS: string[] = this.translate.instant('v10.login.companyLoginErrors.errorCodes');
			if (errorCodesFromWCS.includes(String(errorFromWcs.errorCode))) {
				if (this.deviceService.isDesktop()) {
					descriptionText = errorFromWcs.description;
					buttonText = errorFromWcs.confirmButtonText || buttonText;
					this.buttonHandlerTypeD = () => {
						window.open(this.translate.instant('login.buttonList.loginExternalLink.extLinkURL'));
					};
				} else {
					buttonText = errorFromWcs.dismissButtonText;
				}
			}
		}
		this.spMva10OverlayService.showErrorOverLay = true;
		this.spMva10OverlayService.configureAndOpenOverlay(
			null,
			descriptionText,
			buttonText,
			null,
			this.buttonHandlerTypeD,
			null
		);
		if (overlayTrayIndex) {
			this.spMva10OverlayService.trayIndex = overlayTrayIndex;
		}
	}
	public navigateUrlOrDashBoard(url: string): void {
		if (url && this.deepLinkService.isDeepLink) {
			this.router.navigateByUrl(url);
		} else {
			this.router.navigate([pagesConfig.config.dashboard.name]);
		}
	}
	public queryErrorManager(queryError: string): string {
		let queryErrorCode: string;
		if (
			queryError === logInQueryErrorType.logInQueryErrorNoCompany112 ||
			queryError === logInQueryErrorType.logInQueryErrorNoCompany113 ||
			queryError === logInQueryErrorType.logInQueryErrorNoCompany141
		) {
			queryErrorCode = logInQueryErrorMessage.logInQueryErrorMessageNoCompany113;
		} else if (queryError === logInQueryErrorType.logInQueryErrorCifManagement116) {
			queryErrorCode = logInQueryErrorMessage.logInQueryErrorMessageCifManagement116;
		} else if (queryError === logInQueryErrorType.logInQueryErrorNoSites117) {
			queryErrorCode = logInQueryErrorMessage.logInQueryMessageErrorNoSites117;
		} else if (queryError === logInQueryErrorType.desktopNeeded) {
			queryErrorCode = logInQueryErrorType.logInQueryErrorDesktopNeeded;
		} else {
			queryErrorCode = logInQueryErrorMessage.logInQueryErrorMessageNotAvailableCommon;
		}
		return queryErrorCode;
	}
}
